import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoaderService {

  public isSomethingLoading: number = 0;
  public observer: EventEmitter<number> = new EventEmitter();

  constructor() { }

  public addLoader() {
    this.isSomethingLoading++;
    this.observer.emit(this.isSomethingLoading);
  }

  public removeLoader() {
    (this.isSomethingLoading > 0) ? this.isSomethingLoading-- : "";
    this.observer.emit(this.isSomethingLoading);
  }

}
