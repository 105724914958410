import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Historic } from '../../Class/historic';

@Injectable()
export class HistoricService {

    public links: Array<Historic> = [];
    //Le plus souvent on nomme cette variable : observe
    public observer: EventEmitter<Historic[]> = new EventEmitter();
    urlActive: string = '';

    constructor(private _router: Router) {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.urlActive = event.url.split("/gestion/").pop();
                this.urlActive = "/gestion/" + this.urlActive;
            }
        });
    }

    activeUpdate() {
        for (let i = 0; i < this.links.length; i++) {
            (this.links[i].url == this.urlActive) ? this.links[i].active = true : this.links[i].active = false;
        }
        this.observer.emit(this.links);
    }

    addHistoric(item: Historic) {
        let pushed = false;
        for (let i = 0; i < this.links.length; i++) {
            if (this.links[i].url == item.url) {
                pushed = true;
                break;
            }
        }
        if (!pushed) {
            this.links.push(item);
            this.observer.emit(this.links);
        }
        this.activeUpdate();
    }

    removeHistoric(item: Historic) {
        let historicListPrevious = this.links;
        for (let i = 0; i < historicListPrevious.length; i++) {
            if (historicListPrevious[i].url == item.url) {
                this.links.splice(i, 1);
                this.goToPreviousTab(i-1);
                break;
            }
        }
        this.observer.emit(this.links);
    }

    //::: Ancienne fonction de MDSAP a adapter si besoin pour mvm Clique sur le bouton "fermer tous les onglets" ::://
    resetHistoric() {
        for (let i = 0; i < this.links.length; i++) {
            /*::: si on n'est pas sur l'url "dashboard", on supprime tout le tableau d'historiques,
            on redirige la page vers l'url "dashboard" et on rajoute l'onglet "Tableau de bord" :::*/
            if (this.urlActive !== "dashboard") {
                this.links.splice(0, this.links.length);
                this.observer.emit(this.links);
                this.urlActive = "dashboard";
                this._router.navigate(['/app/' + this.urlActive]);
                this.addHistoric(new Historic({ url: "dashboard", title: "Tableau de bord" }));
            }
            //::: sinon, on supprime tous les éléments à partir de l'index 1 du tableau des historiques ::://
            else {
                this.links.splice(1, this.links.length);
                this.observer.emit(this.links);
            }
        }
    }

    goToPreviousTab(index) {
        if (index >= 0) {
            this._router.navigate([this.links[index].url]);
        }
        else {
            this._router.navigate([this.links[0].url]);
        }
    }

    closeActive() {
        for (let i = 0; i < this.links.length; i++) {
            if (this.links[i].active == true) {
                this.removeHistoric(this.links[i]);
                break;
            }
        }
    }

    changeTitle(title) {
        for (let i = 0; i < this.links.length; i++) {
            if (this.links[i].active == true) {
                this.links[i].title = title;
            }
        }
    }

    changeUrl(newUrl: string) {
        for (let i = 0; i < this.links.length; i++) {
            if(this.links[i].active == true) {
                this.links[i].url = newUrl
            }
        }
    }
}
