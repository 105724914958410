import { SlEventService } from './Components/source-listes/sl-add-edit/sl-event-manager.service';
import { ClientService } from './Services/client.service';
import { SecteurService } from './Services/secteur.service';
import { SocieteService } from './Services/societe.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app.routes';


//Components :
import { AppComponent } from './app.component';
import { SlHomeComponent } from './Components/source-listes/sl-home/sl-home.component';
import { AlertsComponent } from './Components/alerts/alerts.component';
import { HistoricComponent } from './Components/historic/historic.component';
import { LoginComponent } from './Pages/login/login.component';
import { MvmComponent } from './Pages/mvm/mvm.component';
import { HeaderComponent } from './Components/header/header.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { UsersComponent } from './Components/users/users.component';
import { OrganigrammesComponent } from './Components/organigrammes/organigrammes.component';
import { SocieteComponent } from './Components/societe/societe.component';
import { SlAddEditComponent } from './Components/source-listes/sl-add-edit/sl-add-edit.component';
import { ProfilDialogRefComponent } from './Components/profil-dialog-ref/profil-dialog-ref.component';
import { ErrorMessageComponent } from './Components/error-message/error-message.component';
import { ConfirmDialogComponent } from './Components/confirm-dialog/confirm-dialog.component';
import { TabSlComponent } from './Components/source-listes/sl-add-edit/partials/tab-sl/tab-sl.component';
import { TabDossierComponent } from './Components/source-listes/sl-add-edit/partials/tab-dossier/tab-dossier.component';
import { TabProfilComponent } from './Components/source-listes/sl-add-edit/partials/tab-profil/tab-profil.component';
import { TabCvComponent } from './Components/source-listes/sl-add-edit/partials/tab-cv/tab-cv.component';
import { ResearchComponent } from './Components/research/research.component';
import { ArboDragComponent } from './Components/source-listes/sl-add-edit/partials/tab-sl/partials/arbo-drag/arbo-drag.component';
import { RemunerationDialogComponent } from './Components/remuneration-dialog/remuneration-dialog.component';
import { LangueDialogComponent } from './Components/langue-dialog/langue-dialog.component';
import { ResearchDialogRefComponent } from './Components/research-dialog-ref/research-dialog-ref.component';
import { TransfertSocieteComponent } from './Components/transfert-societe/transfert-societe.component';
import { AddSocieteDialogComponent } from './Components/add-societe-dialog/add-societe-dialog.component';
import { SpeedSearchComponent } from './Components/speed-search/speed-search.component';

//Singleton Service :
import { LoaderService } from './Services/loader.service';
import { HistoricService } from './Components/historic/historic.service';
import { HttpClient } from './Services/Tools/http-client.service';
import { AuthService } from './Services/Tools/auth.service';

//Directives
import { AutoSizeDirective } from './Directives/auto-size.directive';




import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
  } from '@angular/material';




@NgModule({
    declarations: [
        AppComponent,
        SlHomeComponent,
        SlHomeComponent,
        AlertsComponent,
        HistoricComponent,
        LoginComponent,
        MvmComponent,
        HeaderComponent,
        PageNotFoundComponent,
        UsersComponent,
        OrganigrammesComponent,
        SocieteComponent,
        SlAddEditComponent,
        ProfilDialogRefComponent,
        ErrorMessageComponent,
        ConfirmDialogComponent,
        AutoSizeDirective,
        TabSlComponent,
        TabDossierComponent,
        TabProfilComponent,
        ResearchComponent,
        TabCvComponent,
        ArboDragComponent,
        RemunerationDialogComponent,
        LangueDialogComponent,
        ResearchDialogRefComponent,
        TransfertSocieteComponent,
        AddSocieteDialogComponent,
        SpeedSearchComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        Ng2FilterPipeModule,
        NgxDatatableModule,
        NgxWebstorageModule.forRoot(),
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule
    ],
    providers: [HistoricService, LoaderService, HttpClient, AuthService, SocieteService, SecteurService, ClientService, SlEventService],
    entryComponents: [
        UsersComponent,
        AlertsComponent,
        ProfilDialogRefComponent,
        ErrorMessageComponent,
        ConfirmDialogComponent,
        RemunerationDialogComponent,
        LangueDialogComponent,
        ResearchDialogRefComponent,
        TransfertSocieteComponent,
        AddSocieteDialogComponent,
        SpeedSearchComponent
    ], //Declaration des composant autonome, exemple Dialog Component
    bootstrap: [AppComponent]
})

export class AppModule {


}
