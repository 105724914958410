import { Router } from '@angular/router';
// import { setTimeout } from 'timers';
import { SlEventService } from '../source-listes/sl-add-edit/sl-event-manager.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatSnackBarConfig } from '@angular/material';
import { Etat } from '../../Class/etat';
import { Component, OnInit,Inject } from '@angular/core';
import { ResearchService } from './../../Services/research.service';

@Component({
      selector: 'app-speed-search',
      templateUrl: './speed-search.component.html',
      styleUrls: ['./speed-search.component.less'],
      providers:[ResearchService]
})
export class SpeedSearchComponent implements OnInit {

      public search: any = {};
      public profils : any = [];
      public profilsSelected  = [];

      public table : any;

      constructor(
            private _researchService : ResearchService,
            private mdSnackBar : MatSnackBar,
            public dialogRef: MatDialogRef<SpeedSearchComponent>,
            public _slEvent : SlEventService,
            private router: Router,
            @Inject(MAT_DIALOG_DATA) public data: any) {

      }

      speedForce(search){
            let finder : any = {};
            finder.source_liste = this.data.id;

            let mdSnackBarConfig = new MatSnackBarConfig();
            mdSnackBarConfig.duration = 3000;
            mdSnackBarConfig.panelClass = 'success';

            for(let key in search)
            {
                if(search[key] != "") finder[key] = search[key];
            }

            this._researchService.getProfilesBySourceListe(finder).subscribe(
                  data => {
                        this.profils = data;
                  },
                  err => console.error(err),
                  () => this.mdSnackBar.open("Recherche terminée", null, mdSnackBarConfig)
            );
      }

      goToProfil(){
            let row = this.profilsSelected;
            if(row.length){
                  let data = row[0];

                  setTimeout(()=>{
                        this._slEvent.set_arboActive(data.societe.id);
                        this._slEvent.set_editSociete(data.societe.id);
                        this._slEvent.set_profil(data.profil);
                        this.dialogRef.close();
                  },50);
            }
      }

      ngOnInit() {
      }


      onSort(event) {
            const rows = [...this.profils];
            const sort = event.sorts[0];

            let property = event.column.name;
            let direction = event.newValue; //asc, desc , end event.prevValue

            let v1 = -1, v2 = 1;
            if (direction == "desc") v1 = 1, v2 = -1;


            if (property == "nom" || property == "prenom" || property == "age" || property == "tel_portable" || property == "fonction" || property == "fonction"
            || property == "souhaits" || property == "parcours" || property == "commentaire" || property == "langues" || property == "diplomes" || property == "remuneration") { //Basic, clé de premier niveau
                let prop = property;
                rows.sort((a, b) => {
                    if(a.profil[prop] == null) a.profil[prop] = "";
                    if(b.profil[prop] == null) b.profil[prop] = "";
                    if (a.profil[prop].toLowerCase() < b.profil[prop].toLowerCase()) return v1;
                    if (a.profil[prop].toLowerCase() > b.profil[prop].toLowerCase()) return v2;
                    return 0;
                });
            }


            if(property == "latestProfilEtat"){
                rows.sort((a, b) => {

                    if(a.latestProfilEtat == null) { a.latestProfilEtat = new Etat({id: null, nom : ""}) }
                    if(b.latestProfilEtat == null) { b.latestProfilEtat = new Etat({id: null, nom : ""}) }

                    if (a.latestProfilEtat.nom.toLowerCase() == b.latestProfilEtat.nom.toLowerCase())return 0;
                    if (a.latestProfilEtat.nom.toLowerCase() < b.latestProfilEtat.nom.toLowerCase()) return v1;
                    if (a.latestProfilEtat.nom.toLowerCase() > b.latestProfilEtat.nom.toLowerCase()) return v2;
                });
            }

            if (property == "source_liste") {
                rows.sort((a, b) => {
                    if (a.source_liste.titre < b.source_liste.titre) return v1;
                    if (a.source_liste.titre > b.source_liste.titre) return v2;
                    return 0;
                });
            }

             if (property == "societe") {
                rows.sort((a, b) => {
                    if (a.societe.nom < b.societe.nom) return v1;
                    if (a.societe.nom > b.societe.nom) return v2;
                    return 0;
                });
            }
            this.profils = rows;
        }

}
