import { Client } from './client';
export class SourceListe {

		id ?: number = null;
		client_id ?: number;
		client ?: Client = new Client();
		reference ?: string = "";
		dateDebut : Date;
		dateFin : Date;
        date_debut ?: string = "";
		date_fin ?: string = "";
		titre ?: string = "";
		commentaire ?: string = "";
		archive ?: boolean;
		date_creation ?: string = "";
		date_modification ?: string = "";
        dateCreation ?: Date;
		dateModification ?: Date;
		etat : string = "Encours";

        constructor(options : {} = null){
            if (options) Object.assign(this, options)
            this.generateDate();
    	}


        generateDate(){
            if(this.date_debut) this.dateDebut = new Date(this.date_debut);
            if(this.date_fin) this.dateFin = new Date(this.date_fin);
            if(this.date_modification) this.dateModification = new Date(this.date_modification);
            if(this.date_creation)  this.dateCreation = new Date(this.date_creation);
        }

        

}
