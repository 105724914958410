import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-dialog-ref',
  templateUrl: './research-dialog-ref.component.html',
  styleUrls: ['./research-dialog-ref.component.less']
})
export class ResearchDialogRefComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ResearchDialogRefComponent>) { }

  ngOnInit() {
  }

}
