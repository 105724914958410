import { Subscription } from 'rxjs/Subscription';
import { SlEventService } from './../../../../sl-event-manager.service';
import { SourceListeService } from './../../../../../../../Services/source-liste.service';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { HistoricService } from "./../../../../../../historic/historic.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: 'app-arbo-drag',
  template: `
    <ul [id]="(popup) ? 'popModeDrag' : 'noPopUpModeDrag' "> 
      <li *ngFor="let item of rows; let i = index;">
        <div [class]="(rowActive == item.id) ? 'row actif' : 'row'" title="{{item.nom}}" [draggable]="true" (dragleave)="dragLeave($event)" (drop)="drop($event)"  (dragover)="allowDrop($event)" (dragstart)="drag($event)" id="{{item.id}}" >
        <mat-icon *ngIf="item.children != undefined && item.children.length" (click)="openChildren( i + '_' + key, item.id )">arrow_drop_down_circle</mat-icon> 
        <mat-icon *ngIf="item.dossier">folder</mat-icon> 
          <span (click)="setActif(item.id )" class="title">{{item.nom}} ({{item.profilCount}})</span>
        </div>
        <app-arbo-drag [popup]="popup" [sl]="sl"  id="{{ i + '_' + key}}" style="display:block;" *ngIf="item.children != undefined && item.children.length" [rows]="item.children"></app-arbo-drag>
      </li>
    </ul>  
  `,
  styleUrls: ['./arbo-drag.component.less'],
  providers : [SourceListeService]
})
export class ArboDragComponent implements OnInit {

  public key : number = Math.floor((Math.random() * 9999) + 1);

  @Input() rows: any;
  @Input() sl: any;
  @Input() popup : boolean = false;

  private subscriptions: Array<Subscription> = [];
  public rowActive : number = -2 ;


  constructor(private _sourceListeService : SourceListeService, private _slEvent : SlEventService, private _historicService: HistoricService, private router: Router, private location: Location,
  private activatedRoute : ActivatedRoute) { }

  drop(event){
    event.preventDefault();
    let societeId = event.dataTransfer.getData("text");
    let societeParentId = event.target.parentNode.getAttribute('id'); 
    let divZone = document.getElementById(societeParentId);
    if(divZone){
      divZone.classList.remove('div-zone');
    }
    if(societeId && societeParentId && societeId != societeParentId ){
      this._sourceListeService.updateSocieteParent(societeId, societeParentId, +this.sl).subscribe(res => {
        this._slEvent.run_reloadArbo();
        this._slEvent.set_editSociete(societeId);
      });
    }
  }//drop(event)

  drag(event){
     event.dataTransfer.setData("text", event.target.id);
  }//drag(event)

  allowDrop(event){
    event.preventDefault();
    let divZone = document.getElementById( event.target.parentNode.getAttribute('id' ));
    if(divZone){
      divZone.classList.add('div-zone');
    }
  }//allowDrop(event)

  dragLeave(event){
    event.preventDefault();
    let divZone = document.getElementById( event.target.parentNode.getAttribute('id' ));
    if(divZone){
      divZone.classList.remove('div-zone');
    }
  }//dragLeave(event)

  setActif(rowId){
    if(this.popup != undefined && this.popup == true){

      if(document.getElementById('popModeDrag').getElementsByClassName('actif').length){
        document.getElementById('popModeDrag').getElementsByClassName('actif')[0].classList.remove('actif');
      }
      
      document.getElementById(rowId).classList.add('actif');
      
    }
    else{
      if(document.getElementById('noPopUpModeDrag').getElementsByClassName('actif').length){
        document.getElementById('noPopUpModeDrag').getElementsByClassName('actif')[0].classList.remove('actif');
      }
      
      document.getElementById(rowId).classList.add('actif');
    }
    

    if( this.popup == undefined || this.popup == false){
        // TO DO, A remplacer par un event de sortie sur la séléction de la société 
        this._slEvent.set_arboActive(rowId);
        //this.rowActive = rowId;
        this._historicService.changeUrl("/gestion/source-liste/"+this.sl+"/"+rowId);
        this.location.replaceState("/gestion/source-liste/"+this.sl+"/"+rowId);
    }
    else{
      this._slEvent.set_arboActive_popup(rowId);
    }
    

  }

  openChildren(id : string, rowId){
    //CLASS FOR FOLDER OPEN
    let el = document.getElementById(rowId);
    (el.classList.contains('open')) ? el.classList.remove('open') : el.classList.add('open');
    //SET LISTE CHILDREN
    let target =  document.getElementById(id);
    if(target){
      let initialAttr = target.getAttribute('style');
      if(initialAttr == "display:none;"){
        target.setAttribute('style', "display:block;");
      }
      else{
        target.setAttribute('style', "display:none;");
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
    });
}

  ngOnInit() {

      this.activatedRoute.params.subscribe(res => {
        if(res['sc_id'] != undefined && +res['sc_id']){
          this.rowActive = +res['sc_id'];
        }
      });

      this.subscriptions.push(
        this._slEvent.arboActive.subscribe(res =>{
          this.rowActive = res;
  
          if(this.popup == false || this.popup === undefined){
            if(document.getElementById('noPopUpModeDrag') && document.getElementById('noPopUpModeDrag').getElementsByClassName('actif').length){
              document.getElementById('noPopUpModeDrag').getElementsByClassName('actif')[0].classList.remove('actif');
            }
            if(document.getElementById(''+this.rowActive)) document.getElementById(''+this.rowActive).classList.add('actif');
          }
  
        })
      )

    

  }//ngOnInit()

}
