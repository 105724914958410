import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-sl-add-edit',
  templateUrl: './sl-add-edit.component.html',
  styles: ['']
})
export class SlAddEditComponent implements OnInit {



  constructor(){
    
    
  }
  ngOnInit() {


  }

}
