import { Remuneration } from './../../Class/remuneration';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-remuneration-dialog',
  templateUrl: './remuneration-dialog.component.html',
  styleUrls: ['./remuneration-dialog.component.less']
})
export class RemunerationDialogComponent implements OnInit {

  public remuneration = new Remuneration();

  constructor(
    public dialogRef: MatDialogRef<RemunerationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data){
      this.remuneration = data;
      this.remuneration.date_creation = new Date(data['date_creation']);
      this.remuneration.date_remuneration = this.convertDateForFront(data['date_remuneration']);

    }
  }

  public convertDateForFront(str: any) {
    if (str != undefined && str != null) {
        return str.substring(0, 10);
    } else {
        return "";
    }
}



  ngOnInit() {

  }

}
