import { Subscription } from 'rxjs/Subscription';
import { SlEventService } from './../source-listes/sl-add-edit/sl-event-manager.service';
import { SocieteService } from './../../Services/societe.service';
import { SecteurService } from './../../Services/secteur.service';
import { SourceListe } from './../../Class/source-liste';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { Societe } from './../../Class/societe';
import { Secteur } from './../../Class/secteur';


@Component({
    selector: 'app-add-societe-dialog',
    templateUrl: './add-societe-dialog.component.html',
    styleUrls : ['./add-societe-dialog.component.less'],
    providers: [SecteurService]
})
export class AddSocieteDialogComponent implements OnInit {

    currentSourceListe: SourceListe;
    currentSociete : Societe = new Societe();


    societeName: string = ""
    secteurName: string = ""

    secteurs: Secteur[] = []
    tempSecteurs: Secteur[] = []

    societes: Societe[] = [];
    tempSocietes: Societe[] = [];


    arbo: any[];


    private subscriptions: Array<Subscription> = [];




    constructor(public dialogRef: MatDialogRef<AddSocieteDialogComponent>, private _secteurService: SecteurService, private _societeService: SocieteService, private _slEvent : SlEventService, @Inject(MAT_DIALOG_DATA) public data: any ) {

     }


    filterSecteurAutoComplete(event) {
        const val = event.toLowerCase();
        const temp = this.tempSecteurs.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.secteurs = temp;
    }

    filterSecteur(event) {
        const val = event;
        const temp = this.tempSocietes.filter(function (d) {
            return d.hasOwnProperty("secteur") && d.secteur != null && d.secteur.id == val;
        });
        this.societes = temp;
    }

    generateArbo(data: Societe[]) {
        //this.arbo
        this.arbo = [];
        let doItAgain = true;
        const tabOrigine = data;
        let arbos = [];
        while (doItAgain) {
            doItAgain = false;
            for (let i = 0; i < tabOrigine.length; ++i) {
                let elt = tabOrigine[i];
                if (!elt.parent) {
                    arbos['_' + elt.id] = elt;
                }
                else {
                    if (arbos['_' + elt.parent.id]) {
                        (!arbos['_' + elt.parent.id].hasOwnProperty('children')) ? arbos['_' + elt.parent.id].children = [] : '';
                        let elementExist = false;
                        for (let x = 0; x < arbos['_' + elt.parent.id].children.length; ++x) {
                            if (arbos['_' + elt.parent.id].children[x].id == elt.id) {
                                elementExist = true;
                                break;
                            }
                        }
                        if (!elementExist) arbos['_' + elt.parent.id].children.push(elt);
                        arbos['_' + elt.id] = elt;
                    }
                    else {
                        doItAgain = true;
                    }
                }
            }
        }

        let totalProfil = 0;
        let arboTemp = [];
        for (let i = 0; i < data.length; i++) {
            totalProfil += +data[i].profilCount;
            if (arbos["_" + data[i].id].parent == null) {
                arboTemp.push(arbos["_" + data[i].id]);
            }
        }
        this.arbo = arboTemp;

    }//END generateArbo()

    search() {
        let search = this.societeName;
        let secteur: number = null;
        for (let i = 0; i < this.secteurs.length; i++) {
            if (this.secteurs[i].nom == this.secteurName) {
                secteur = +this.secteurs[i].id;
                break;
            }
        }
        this._societeService.getSocietesResearch(search, secteur).subscribe((res: Societe[]) => {
            this.societes = res
            this.tempSocietes = [...res];
            this.generateArbo(res);
        })


    }

    addSocieteToArboProcess(){
        this._slEvent.set_addSocietePopUp(this.currentSociete);
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.currentSourceListe = this.data;
        this.currentSociete.secteur = new Secteur({id : null, nom : ""});
        this.currentSociete.nom ="";
        this.currentSociete.adresse = "";
        this.currentSociete.commentaire = "";
        this.currentSociete.telephone = "";    

        this._secteurService.getSecteurs().subscribe(res => {
            this.secteurs = res;
            this.tempSecteurs = [...this.secteurs];
        });

        this.subscriptions.push(

            this._slEvent.arboActive_popup.subscribe(res => {
                if(+res){
                    this._societeService.getSocieteById(+res).subscribe((res : Societe) => {
                        if(res.secteur == null) res.secteur = new Secteur({id : null, nom : ""});
                        this.currentSociete = res;
                    })
                }
            })
    
        )
    }

}
