import { ProfilResearch } from './../../../Class/profil-research';
import { ProfilSociete } from './../../../Class/profil-societe';
import { Profil } from './../../../Class/profil';
import { SourceListe } from './../../../Class/source-liste';
import { Societe } from './../../../Class/societe';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class SlEventService {

    public reloadArbo: EventEmitter<any>
    public reloadProfils: EventEmitter<any>

    public arboActive : EventEmitter<number>;
    public arboActive_popup : EventEmitter<number>;

    public sourceListeAddEdit :  EventEmitter<SourceListe>
    public profilAddEdit : EventEmitter<Profil>

    public createFolder : EventEmitter<Boolean>
    public createSociete : EventEmitter<any>

    public editSociete : EventEmitter<number>; //Societe ID

    public societeForProfil : EventEmitter<Societe>;
    public profilsForSourceListe : EventEmitter<ProfilResearch[]>;

    public setSocietePopUp : EventEmitter<number>; //Societe ID
    public addSocietePopUp :  EventEmitter<Societe>;



    constructor() {
        this.reloadArbo = new EventEmitter()
        this.reloadProfils = new EventEmitter()
        this.sourceListeAddEdit = new EventEmitter()
        this.profilAddEdit = new EventEmitter() 
        this.createFolder = new EventEmitter()
        this.createSociete = new EventEmitter()
        this.editSociete = new EventEmitter()
        this.societeForProfil = new EventEmitter();
        this.arboActive = new EventEmitter();
        this.profilsForSourceListe = new EventEmitter();
        this.setSocietePopUp = new EventEmitter();
        this.addSocietePopUp = new EventEmitter();
        this.arboActive_popup = new EventEmitter();
    }


    public set_sourceListe(data : SourceListe){
        this.sourceListeAddEdit.emit(data)
    }

    public set_profil(data : Profil){
        this.profilAddEdit.emit(data)
    }

    public set_createFolder(data : boolean){
        this.createFolder.emit(data)
    }
    public set_createSociete(data : Societe = null){ //Si data = null , alors création d'une société directement dans la source liste si avec parent_id de data.id
        this.createSociete.emit(data)
    }

    public set_editSociete(id : number){
        this.editSociete.emit(id);
        
    }

    public run_reloadArbo(){
        this.reloadArbo.emit(true);
    }

    public run_reloadProfil(){
        this.reloadProfils.emit(true);
    }

    public set_societeForProfil(data : Societe){
        this.societeForProfil.emit(data);
    }

    public set_arboActive(id : number){
        
        this.arboActive.emit(+id);
        this.set_editSociete(+id);
    }

    public set_arboActive_popup(id:number){
        this.arboActive_popup.emit(+id);
    }
    public set_profilsForSourceListe(data : ProfilResearch[]){
        this.profilsForSourceListe.emit(data);
    }

    public set_societe_for_popup_add(societeId : number){
        this.setSocietePopUp.emit(societeId);
    }

    public set_addSocietePopUp(societe:Societe, profil : boolean = false){
        //Set societe for add to the sourceListe and add profil or not ?
        this.addSocietePopUp.emit(societe);
    }

}
