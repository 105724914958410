import { environment } from './../../environments/environment';
import { LoaderService } from './loader.service';
import { Secteur } from './../Class/secteur';
import { HttpClient } from './Tools/http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';  // Updated for Angular 6/RxJS 6
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class SecteurService {

  constructor(private _http: HttpClient, private _loaderService: LoaderService) { }

  private secteurs: Secteur[];
  private secteursIsUpToDate: boolean = false;

  public getSecteurs() {
    if (!this.secteursIsUpToDate) {
      this._loaderService.addLoader();
      return this._http.get(environment.API_URL + "SocieteSecteurs/list")
      .pipe(
        map(res => {
          this.secteurs = res as Secteur[];
          this.secteursIsUpToDate = true;
          this._loaderService.removeLoader();
          return this.secteurs;
        }),
        catchError((error: Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
        })
      );
    }
    else {
      return Observable.create(observer => {
        observer.next(this.secteurs);
        observer.complete();
      });
    }
  }

}
