import { Profil } from './profil';
export class Remuneration {

        public id : number = null;
        public remuneration: string = '';
        public date_remuneration: Date = new Date();
        public archive: boolean = false;
        public date_creation: Date = new Date();
        public date_modification: Date = new Date();
        public profil?: Profil;


        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
    	}

}
