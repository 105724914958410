import { environment } from './../../environments/environment';
import { HttpClient } from './Tools/http-client.service';
import { LoaderService } from './loader.service';
import { Client } from './../Class/client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ClientService {


  constructor(private _loaderService: LoaderService, private _http: HttpClient) { }

  public getClients() {
      
      this._loaderService.addLoader();
      return this._http.get(environment.API_URL + "clients/list").map(res => {
        this._loaderService.removeLoader();
        return res as Client[];
      });

  }

}
