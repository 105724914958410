import { Remuneration } from './remuneration';
import { Societe } from './societe';
export class Profil {

		public id ?: number;
		public societe_id ?: number = null;
		public societe ?: Societe = null;
		public nom ?: string = "";
		public prenom ?: string = "";
		public age ?: number = null;
		public adresse ?: string = "";
		public email_professionnel ?: string = "";
		public email_personnel ?: string = "";
		public tel_bureau ?: string = "";
		public tel_portable ?: string = "";
		public tel_domicile ?: string = "";
		public diplomes ?: string = "";
		public langues ?: string = "";
		public parcours ?: string = "";
		public ordre ?: number = null;
		public fonction ?: string = "";
		public remunerations ?: Remuneration[] = [];
        public remuneration ?: string = "";
		public souhaits ?: string = "";
		public commentaire ?: string = "";
		public archive ?: boolean = false;
		public date_creation ?: Date = null;
        public dateCreation ?: Date;
		public date_modification ?: Date = null;
        public dateModification ?: Date;
        public cv_filename : string = null;

        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
            this.generateDate()
    	}


        generateDate(){
            if(this.date_modification) this.dateModification = new Date(this.date_modification);
            if(this.date_creation) this.dateCreation = new Date(this.date_creation);
        }



        

}
