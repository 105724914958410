import { SourceListeSociete } from './../Class/source-liste-societe';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../environments/environment';
import { Societe } from './../Class/societe';
import { HttpClient } from './Tools/http-client.service';
import { LoaderService } from './loader.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SocieteService {

  public societes: Societe[];
  public societesIsUpToDate: boolean;

  constructor(private _loaderService: LoaderService, private _http: HttpClient) {
    this.societes = [];
    this.societesIsUpToDate = false;
  }

  public getSocietes() {  // Listes 
      this._loaderService.addLoader();
      return this._http.get(environment.API_URL + "societes/list").map(res => {
        this._loaderService.removeLoader();
        this.societes = res as Societe[];
        this.societesIsUpToDate = true;
        return this.societes;
      });
  }

  public getSocieteById(id: number) { //Get by ID
    this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "societes/" + id + "/show").map(res => {
      this._loaderService.removeLoader();
      return res as Societe;
    });
  }

  public patchSociete(data: Societe) { //Update
    this._loaderService.addLoader();
    return this._http.patch(environment.API_URL + "societes/" + data.id + "/update", data).map(res => {
      this._loaderService.removeLoader();
      this.societesIsUpToDate = false;
      return res as Societe;
    });
  }


  public patchSourceListeSociete(data: SourceListeSociete) { //Update
    this._loaderService.addLoader();
    return this._http.patch(environment.API_URL + "SourceListeSocietes/update", data).map(res => {
      this._loaderService.removeLoader();
      this.societesIsUpToDate = false;
      return res as Societe;
    });
  }
  public postSourceListeSocoete(data: SourceListeSociete) { //Update
    this._loaderService.addLoader();
    return this._http.post(environment.API_URL + "SourceListeSocietes/new", data).map(res => {
      this._loaderService.removeLoader();
      this.societesIsUpToDate = false;
      return res as Societe;
    });
  }

  public postSociete(data: Societe) { //Create
    this._loaderService.addLoader();
    return this._http.post(environment.API_URL + "societes/new", data).map(res => {
      let item = res as Societe
      this._loaderService.removeLoader();
      if (this.societesIsUpToDate) {
        this.societes.unshift(item);;
      }
      return item;
    });
  }

  public setSocietesCache(temp: Societe[]) { // On reset le cache manuellement pour éviter de refaire un appel API sur les sociétées
    this.societes = temp;
  }

  public deleteSociete(id: number) {
    this._loaderService.addLoader();
    return this._http.delete(environment.API_URL + "societes/" + id + "/delete").map(res => {
      this._loaderService.removeLoader();
      return res;
    });
  }

  public getSocieteBySourceListe(sourceListeId: number, societeId: number){
    this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "SourceListeSocietes/"+sourceListeId+"/"+societeId+"/show").map(res => {
      this._loaderService.removeLoader();
      return res as SourceListeSociete;
    });
  }

  public getSocietesResearch(research : string, secteurId : number){
      let data : any = {nom : research, secteur : secteurId}
      this._loaderService.addLoader();
      return this._http.post(environment.API_URL+"societes/recherche", data).map(res =>{
          this._loaderService.removeLoader();
          return res as any[]
      })
  }

}
