import { Subscription } from 'rxjs/Subscription';
import { Etat } from './../../../../../Class/etat';
import { ConfirmDialogComponent } from './../../../../confirm-dialog/confirm-dialog.component';
import { SourceListeService } from './../../../../../Services/source-liste.service';
import { TransfertSocieteComponent } from './../../../../transfert-societe/transfert-societe.component';
import { ProfilResearch } from './../../../../../Class/profil-research';
import { environment } from './../../../../../../environments/environment';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ResearchDialogRefComponent } from './../../../../research-dialog-ref/research-dialog-ref.component';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ProfilSociete } from './../../../../../Class/profil-societe';
import { SocieteService } from './../../../../../Services/societe.service';
import { ProfilService } from './../../../../../Services/profil.service';
import { SecteurService } from './../../../../../Services/secteur.service';
import { SlEventService } from './../../sl-event-manager.service';
import { Profil } from './../../../../../Class/profil';
import { SourceListeSociete } from './../../../../../Class/source-liste-societe';
import { SourceListe } from './../../../../../Class/source-liste';
import { Societe } from './../../../../../Class/societe';
import { Secteur } from './../../../../../Class/secteur';
import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { HistoricService } from './../../../../historic/historic.service';

@Component({
    selector: 'app-tab-dossier',
    templateUrl: './tab-dossier.component.html',
    styleUrls: ['./tab-dossier.component.less'],
    providers: [ProfilService, SourceListeService]
})
export class TabDossierComponent implements OnInit {


    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

    sourceListeActive: SourceListe = new SourceListe();
    createFolder: boolean = false;

    secteurs: Secteur[] = []; //Liste des secteurs
    secteurSelected: string;
    secteur: Secteur;
    tempSecteurs: any;

    public params: Params; //Params by url
    public loadByUrl: boolean = false;

    public profils: ProfilSociete[] = [new ProfilSociete({})];
    public profilSelected: any[] = [];
    public dossierFolderEdit: boolean = false;

    currentSourceListeSociete: SourceListeSociete;

    private subscriptions: Array<Subscription> = [];


    urlActive: string = '';



    constructor(
        private _slEvent: SlEventService,
        private _secteurService: SecteurService,
        private _profilService: ProfilService,
        private _societeService: SocieteService,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private mdSnackBar: MatSnackBar,
        private _historicService: HistoricService,
        private _slService: SourceListeService,
        private location: Location
    ) { }

    _timeout: any = null;
    updateReelTime(event){
        if(this.currentSourceListeSociete.societe.id != null){
            if(this._timeout){   //if there is already a timeout in process cancel it
                window.clearTimeout(this._timeout);
            }
            this._timeout = window.setTimeout( ()=>{
                this._timeout = null;
                this.saveSociete();
            }, 500 );
        }
    }


    getRowClass(profils) {
        return " couleur-" + profils.couleur
    }

    loadUrl() {
        if (!this.loadByUrl) {
            setInterval(() => {
                if (this.params.id != undefined && this.params.sc_id != undefined && this.sourceListeActive.id != null && !this.loadByUrl) {
                    this.loadByUrl = true;
                    //this._slEvent.set_arboActive(this.params.sc_id);
                    //this._slEvent.set_editSociete(this.params.sc_id);

                }
            }, 100);
        }
    }


    addMultipleProfils() {
        let dialogRef = this.dialog.open(ResearchDialogRefComponent, {
            backdropClass: 'light-dialog',
            width: '70%',
            height: '600px'

        });
        dialogRef.afterClosed().subscribe(
            res => {

            },
            err => { }
        );
    }

    print() {
        let url: string = environment.API_URL + "SourceListeSocietes/{1}/{2}/impression";
        url = url.replace("{1}", "" + this.currentSourceListeSociete.source_liste.id);
        url = url.replace("{2}", "" + this.currentSourceListeSociete.societe.id);
        window.open(url, '_blank');
    }

    changeSecteurSelected(item: Secteur) {
        this.secteurSelected = item.nom;
        this.currentSourceListeSociete.societe.secteur = item;
    }

    fiterSecteur(event) {

        const val = event.toLowerCase();
        const temp = this.tempSecteurs.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.secteurs = temp;
        this.table.offset = 0;

    }

    newProfil() {
        let profil = new Profil();
        this._slEvent.set_profil(profil);
    }

    editProfil(event = null) {
        if (event != undefined && event != null && event.profil != undefined) {
            this._slEvent.set_profil(event.profil);
            let url = "/gestion/source-liste/" + this.sourceListeActive.id + "/" + this.currentSourceListeSociete.societe.id + "/" + event.profil.id;
            this.location.replaceState(url);
            this._historicService.changeUrl(url);

        }
    }

    public firstLoadProfil = false;
    loadProfilForSociete(data: SourceListeSociete) {
        this.profils = [
            new ProfilSociete({})
        ];
        this.profilSelected = [];
        //this.table.offset = 0;
        this._profilService.getProfilsBySocieteAndSourceListe(this.sourceListeActive.id, data.societe.id).subscribe(res => {
            this.profils = res;

            //Si on a un paramètre dans l'url qui est un profil ID et que on a jamais chargé de profil par url alors on charge le profil depuis la liste des resusltats de rechercher qui a été lancée directement
            if (this.params.pf_id != undefined && !this.firstLoadProfil) {
                this.firstLoadProfil = true;
                for (let i = 0; i < this.profils.length; i++) {
                    let data = this.profils[i];

                    if (data.profil.id == this.params.pf_id) {
                        this.profilSelected[0] = this.profils[i];
                        this.editProfil(data);
                        break;
                    }
                }

            }
            this.profils = [...this.profils];

            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        });
    }

    save() {
    }

    saveSociete() {

        let id = this.currentSourceListeSociete.societe.id;
        this.currentSourceListeSociete.societe.nom = this.currentSourceListeSociete.societe.nom.toUpperCase();
        if (id && this.currentSourceListeSociete.societe.nom != "" ) {
            this._societeService.patchSourceListeSociete(this.currentSourceListeSociete).subscribe(res => {
                this._slEvent.run_reloadArbo();
            });
        }
        else {
            //(this.dossierFolderEdit) ? this.currentSourceListeSociete.societe.dossier = true : this.currentSourceListeSociete.societe.dossier = false;
            this._societeService.postSourceListeSocoete(this.currentSourceListeSociete).subscribe(res => {
                this.currentSourceListeSociete = new SourceListeSociete(res);

                this._historicService.changeUrl("/gestion/source-liste/"+this.currentSourceListeSociete.source_liste.id+"/"+this.currentSourceListeSociete.societe.id);
                this.location.replaceState("/gestion/source-liste/"+this.currentSourceListeSociete.source_liste.id+"/"+this.currentSourceListeSociete.societe.id);
                this._slEvent.set_societeForProfil(this.currentSourceListeSociete.societe);
                this._slEvent.run_reloadArbo();
                setTimeout(()=>{
                    this._slEvent.set_arboActive(this.currentSourceListeSociete.societe.id);
                },300);



            });
        }
    }

    openTransfertProfil() {
        if (this.profilSelected.length) {
            let dialogRef = this.dialog.open(TransfertSocieteComponent, {
                width: "635px",
                height: "450px",
                data: this.profilSelected[0].profil,
                backdropClass: 'light-dialog',
            });
            dialogRef.afterClosed().subscribe(result => { });
        }

    }

    confirmDelete() {
        let data: any = { profil: "", societe: "", source_liste: "" };
        data.profil = this.profilSelected[0].profil;
        data.societe = this.profilSelected[0].societe;
        data.source_liste = this.currentSourceListeSociete.source_liste;

        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 3000;
        mdSnackBarConfig.panelClass = 'success';

        this._slService.deleteSourceListeProfils(data).subscribe(res => {
            this.mdSnackBar.open("Profil supprimé", "Fermer", mdSnackBarConfig);
            this._slEvent.run_reloadArbo();
            this.loadProfilForSociete(this.currentSourceListeSociete);
        });
    }
    deleteProfilDossier() {
        if (this.profilSelected.length) {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Confirmer la suppresion du profil ?` }, backdropClass: 'light-dialog' });
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this.confirmDelete();
                }
            })
        }

    }


    setByKeybord(event, row, index) {
        this.editProfil(this.profilSelected[0]);
    }


    colorize(id: number) {

        let profilSociete = this.profilSelected[0];
        profilSociete.couleur = id;
        profilSociete.source_liste = this.currentSourceListeSociete.source_liste;

        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 3000;
        mdSnackBarConfig.panelClass = 'success';

        this._profilService.update(profilSociete).subscribe(res => {
            for (let i = 0; i < this.profils.length; i++) {
                if (this.profils[i].profil.id == profilSociete.profil.id) this.profils[i].couleur = id; break;
            }
            this.loadProfilForSociete(this.currentSourceListeSociete);
            this.mdSnackBar.open("Modification terminée", "Fermer", mdSnackBarConfig);
        });
    }

    setTitle(id) {
        switch (id) {
            case id = 0:
                return "Aucune couleur";

            case id = 1:
                return "A contacter";

            case id = 2:
                return "Contacté";

            case id = 3:
                return "Intéressant";

            case id = 4:
                return "Pas intéressant";
            default:
                return "Aucune couleur";

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }


    ngOnInit() {


        this.subscriptions.push(
            //On récupére la sourceListe en cours d'édition
            this._slEvent.sourceListeAddEdit.subscribe((res: SourceListe) => {
                this.sourceListeActive = res;
            }),

            //Création d'un dossier dans la source liste
            this._slEvent.createFolder.subscribe((res: boolean) => {
                this.profilSelected = [];
                this.profils = [new ProfilSociete({})];
                this.createFolder = true;
                this.secteurSelected = "";
                this.currentSourceListeSociete = new SourceListeSociete();
                this.currentSourceListeSociete.source_liste = this.sourceListeActive;
                this.currentSourceListeSociete.societe = new Societe({});
                this.currentSourceListeSociete.societe.dossier = true;
                this.currentSourceListeSociete.parent = null;
                this.currentSourceListeSociete.societe.secteur = null;
            }),
            //OU Création d'une société dans l'item courant ou itemCourant = null alors création d'une société dans la sourceListe
            this._slEvent.createSociete.subscribe((res: Societe = null) => {
                this.profils = [new ProfilSociete({})];
                this.profilSelected = [];
                this.secteurSelected = "";
                this._slEvent.set_profil(new Profil());
                this.createFolder = false;
                this.currentSourceListeSociete = new SourceListeSociete();
                this.currentSourceListeSociete.societe.dossier = false;
                this.currentSourceListeSociete.source_liste = this.sourceListeActive;
                if (res != null) { //If create societe inItemActive
                    this.currentSourceListeSociete.parent = res;
                }
            }),
            //Edition d'une société
            this._slEvent.editSociete.subscribe((res: number) => { //On récupére un id
                this.profils = [new ProfilSociete({})];
                this.profilSelected = [];

                let societeID = res,
                    sourceListeID = this.sourceListeActive.id
                this.secteurSelected = "";
                this.currentSourceListeSociete = new SourceListeSociete();


                this._societeService.getSocieteBySourceListe(sourceListeID, societeID).subscribe((res: SourceListeSociete) => {

                    if(res){
                        (res.societe.dossier) ? this.createFolder = true : this.createFolder = false, this.loadProfilForSociete(res);
                        this.currentSourceListeSociete = res;
                        this._slEvent.set_societeForProfil(res.societe);
                        if (res.societe.secteur != null) {
                            this.secteurSelected = res.societe.secteur.nom;
                            this.secteur = res.societe.secteur;
                        }
                        else {
                            this.secteurSelected = "";
                            this.secteur = null;
                        }
                    }


                });
            }),
            this._slEvent.reloadProfils.subscribe(res => {
                this.loadProfilForSociete(this.currentSourceListeSociete);
            }),

            this._slEvent.profilsForSourceListe.subscribe((res: ProfilResearch[]) => {
                this.dialog.closeAll();
                res.map(item => {
                    let data = {
                        source_liste: this.currentSourceListeSociete.source_liste.id,
                        societe: this.currentSourceListeSociete.societe.id,
                        profil: item.profil.id
                    }
                    this._profilService.moveSourceListeProfils(data).subscribe((res: ProfilSociete) => {



                        this.profils.push(res);

                    });
                })

            })
        );


        this._secteurService.getSecteurs().subscribe(res => {
            this.secteurs = res;
            this.tempSecteurs = [...res];
        });

        //On regarde si il y a un id dans l'url
        this.activatedRoute.params.subscribe(res => { //On regarde les pramètres dans l'url
            this.firstLoadProfil = false;
            this.params = res;
            if (this.params.id != undefined && this.params.sc_id != undefined && this.params.pf_id != undefined) {
                this.loadUrl();
            }
        });




    }






    onSort(event) {
        const rows = [...this.profils];
        const sort = event.sorts[0];

        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;


        if (property == "nom" || property == "prenom" || property == "fonction") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if (a.profil[prop] == null) a.profil[prop] = "";
                if (b.profil[prop] == null) b.profil[prop] = "";
                if (a.profil[prop].toLowerCase() < b.profil[prop].toLowerCase()) return v1;
                if (a.profil[prop].toLowerCase() > b.profil[prop].toLowerCase()) return v2;
                return 0;
            });
        }

        if (property == "latestProfilEtat") {
            rows.sort((a, b) => {

                if (a.latestProfilEtat == null) { a.latestProfilEtat = new Etat({ id: null, nom: "" }) }
                if (b.latestProfilEtat == null) { b.latestProfilEtat = new Etat({ id: null, nom: "" }) }

                if (a.latestProfilEtat.nom.toLowerCase() == b.latestProfilEtat.nom.toLowerCase()) return 0;
                if (a.latestProfilEtat.nom.toLowerCase() < b.latestProfilEtat.nom.toLowerCase()) return v1;
                if (a.latestProfilEtat.nom.toLowerCase() > b.latestProfilEtat.nom.toLowerCase()) return v2;
            });
        }

        this.profils = rows;
    }

    onKeysUpOrDown(event) {
        if(event['event']['keyCode'] == 40){
          this.profils.map((item, index) => {
            if(item['profil']['id'] == event.row['profil']['id']){
              this.editProfil(this.profils[index + 1]);
            }
          });
        }
        else{
          if(event['event']['keyCode'] == 38){
            this.profils.map((item, index) => {
              if(item['profil']['id'] == event.row['profil']['id']){
                this.editProfil(this.profils[index - 1]);
              }
            });
          }

        }
    }
}
