export class User {


        id ?: number;
        nom : string = "";
        prenom : string = "";
        login : string = "";
        password ?: string;

        constructor(options : {} = null){
            if (options) {
                Object.assign(this, options)
            }
        }

        
}
 
