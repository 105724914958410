import { Etat } from './etat';
import { SourceListe } from './source-liste';
import { Profil } from './profil';
export class ProfilSociete {

        public ordre ?: number;
        public couleur ?: number;
        public societe ?: any;
        public profil : Profil = new Profil();
        public source_liste ?: SourceListe;
        public latestProfilEtat ?: Etat ;


        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
    	}

}
