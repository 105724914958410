import { UsersService } from './../../Services/users.service';
import { User } from './../../Class/user';
import { ConfirmDialogComponent } from '../../Components/confirm-dialog/confirm-dialog.component';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { LocalStorageService } from "ngx-webstorage";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less'],
  providers: [UsersService]
})
export class UsersComponent implements OnInit {

  loggedUser: User = this.storage.retrieve('user');
  users: User[];
  userForEdition: User = new User();
  usersSelected : any[] = [];
  ngxColonnes = [{ name: "Nom", prop: "nom" }, { name: "Prénom", prop: "prenom" }, { name: "Login", prop: "login" }];
  changePassword: boolean = true;
  scroll: boolean = true;

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  constructor(public dialogRef: MatDialogRef<UsersComponent>, private _userService: UsersService, private storage: LocalStorageService, private _snackBar: MatSnackBar, private dialog: MatDialog) { }

  onSelect(event) {
    this.userForEdition = event.selected[0];
    this.changePassword = false;
  }

  newUser() {
    this.userForEdition = new User();
    this.changePassword = true;
  }

  deleteUser() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: "Confirmer la suppression de l'utilisateur "+this.userForEdition['nom']+" "+this.userForEdition['prenom']+" ?" },backdropClass: 'light-dialog' });

    let mdSnackBarConfig = new MatSnackBarConfig();
    mdSnackBarConfig.duration = 200;
    mdSnackBarConfig.panelClass = 'success';

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        let user: any = this.userForEdition;
        this._userService.deleteUser(this.userForEdition['id']).subscribe(res => {
          this._snackBar.open("L'utilisateur a été supprimé ✔", null, mdSnackBarConfig);
          let tmp = this.users;
          tmp.splice(user.$$index, 1);
          this.users = tmp;
          this.table.offset = 0;
      }, err => {  } );
    }});

  }

  save() {
    let mdSnackBarConfig = new MatSnackBarConfig();
    mdSnackBarConfig.duration = 200;
    mdSnackBarConfig.panelClass = 'success';

    if (this.userForEdition.hasOwnProperty('id')) {
      this._userService.updateUser(this.userForEdition).subscribe(res => {
        this._snackBar.open("Utilisateur "+this.userForEdition.nom+" "+this.userForEdition.prenom+" modifié", null, mdSnackBarConfig);
      });
    }
    else {
      this._userService.addUser(this.userForEdition).subscribe( (res : User) => {
        if(res.hasOwnProperty("id")) this.users.push(res);
        this._snackBar.open(this.userForEdition.nom+" "+this.userForEdition.prenom+" a été ajouté ", null, mdSnackBarConfig);
      });
    }
  }

  requestChangePassword() {
    this.changePassword = true;
  }

  ngOnInit() {
    this._userService.getUsers().subscribe(response => {
      this.users = response;
    });
  }

}
