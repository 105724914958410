import { SourceListeSociete } from './../Class/source-liste-societe';
import { Societe } from './../Class/societe';
import { environment } from './../../environments/environment';
import { LoaderService } from './loader.service';
import { SourceListe } from './../Class/source-liste';
import { HttpClient } from './Tools/http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class SourceListeService {

    private ETATS = ["Annulée/Interrompue", "Terminée", "Encours"];
    sourcesListes: SourceListe[] = [];
    public societesDragDrop: Societe[] = [];

    constructor(private _http: HttpClient, private _loaderService: LoaderService) { }

    public getEtatList() {
        return this.ETATS;
    }

    public getSourcesListes() {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "SourceListes/list").map(res => {
            let data = res as SourceListe[];
            for (let i = 0; i < data.length; i++) {
                this.sourcesListes[i] = new SourceListe(data[i]);
                this.sourcesListes[i].generateDate();
            }
            this._loaderService.removeLoader();
            return this.sourcesListes;
        });
    }

    public getSourcesListe(id: number) {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "SourceListes/" + id + "/show").map(res => {
            this._loaderService.removeLoader();

            let data = new SourceListe(res); data.generateDate();
            return data;

        });
    }

    public duplicateSourceListe(id: number) {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "SourceListes/" + id + "/duplication").map(res => {
            this._loaderService.removeLoader();
            let data = new SourceListe(res); data.generateDate();
            return data;
        });
    }

    public deleteSourceListe(id: number) {
        this._loaderService.addLoader();
        return this._http.delete(environment.API_URL + "SourceListes/" + id + "/delete").map(res => {
            this._loaderService.removeLoader();
            return res;
        });
    }

    public patchSourceListe(data: SourceListe) {
        this._loaderService.addLoader();
        return this._http.patch(environment.API_URL + "SourceListes/" + data.id + "/update", data).map(res => {
            this._loaderService.removeLoader();
            let data = new SourceListe(res); data.generateDate();
            return data;
        });
    }

    public saveSourceListe(data: SourceListe) {
        this._loaderService.addLoader();
        return this._http.post(environment.API_URL + "SourceListes/new", data).map(res => {
            this._loaderService.removeLoader();
            let data = new SourceListe(res); data.generateDate();
            return data;
        });
    }

    public getSourListSocietes(id: number) {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "SourceListeSocietes/" + id + "/list").map(res => {
            this._loaderService.removeLoader();
            this.societesDragDrop = res as Societe[];
            return this.societesDragDrop
        })
    }


    public updateSocieteParent(societeId: number, parentId: number, sourceListeId: number) {
        let data = { parent_id: parentId, societe_id: societeId, source_liste_id: sourceListeId }
        this._loaderService.addLoader();
        return this._http.patch(environment.API_URL + "SourceListeSocietes/move", data).map(res => {
            this._loaderService.removeLoader();
            return res;
        });

    }


    public addSourceListeSociete(data: SourceListeSociete) {
        // Could be refactored using SociceteService.postSourceListeSocoete(...)?
        this._loaderService.addLoader();
        return this._http.post(environment.API_URL + "SourceListeSocietes/new", data).map(res => {
            this._loaderService.removeLoader();
            return res;
        });
    }


    public initSociete(data) {
        this._loaderService.addLoader();
        return this._http.patch(environment.API_URL + "SourceListeSocietes/init", data).map(res => {
            this._loaderService.removeLoader();
            return res;
        });
    }

    public deleteSociete(data) {
        this._loaderService.addLoader();
        return this._http.delete(environment.API_URL + "SourceListeSocietes/" + data.source_liste.id + "/" + data.societe.id + "/delete").map(res => {
            this._loaderService.removeLoader();
            return res;
        });
    }

    public deleteSourceListeProfils(data: any) {
        this._loaderService.addLoader();
        return this._http.delete(environment.API_URL + "SourceListeProfils/"+data.source_liste.id+"/"+data.societe.id+"/"+data.profil.id+"/delete").map(res => {
            this._loaderService.removeLoader();
            return res;
        });
    }


}
