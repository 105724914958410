import { Societe } from './societe';
import { SourceListe } from './source-liste';
export class SourceListeSociete {

        source_liste : SourceListe = new SourceListe();
        societe : Societe = new Societe();
        commentaire : string = "";
        parent : Societe = new Societe();
	
		constructor(options : {} = null){
            if (options) {
                Object.assign(this, options)
            }
        }
        

}
