import { Societe } from './../Class/societe';
import { ProfilSociete } from './../Class/profil-societe';
import { environment } from './../../environments/environment';
import { HttpClient } from './Tools/http-client.service';
import { LoaderService } from './loader.service';
import { Profil } from './../Class/profil';
import { Injectable } from '@angular/core';

@Injectable()
export class ProfilService {

  profils: Profil[]
  profil: Profil

  constructor(private _loaderService: LoaderService, private _http: HttpClient) { }

  public getProfilsBySocieteId(societeId: number) {
    this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "profils/societe/" + societeId + "/list").map(res => {
      this.profils = res as Profil[];
      this._loaderService.removeLoader();
      return this.profils;
    });
  }

  public getProfilById(id: number) {
    this._loaderService.addLoader();
    return this._http.get(`${environment.API_URL}profils/${id}/show`).map( res => {
      this._loaderService.removeLoader();
      return res;
    });
  }

  public deleteProfil(id: number) {
    this._loaderService.addLoader();
    return this._http.delete(environment.API_URL + "profils/" + id + "/delete").map(res => {
      this._loaderService.removeLoader();
      return res;
    });
  }

  public getProfilsBySocieteAndSourceListe(sourceListeId:number, societeId:number){
     this._loaderService.addLoader();
    return this._http.get(environment.API_URL+"SourceListeProfils/"+sourceListeId+"/"+societeId+"/list").map(res => {
      this._loaderService.removeLoader();
      return res as ProfilSociete[];
    });
  }

  public save(data: Profil){
    this._loaderService.addLoader();
    return this._http.post(environment.API_URL+"profils/new", data).map(res => {
      this._loaderService.removeLoader();
      return res as Profil;
    })
  }

  public patchAndSave(data : Profil, societe : Societe){
    if(data.id == undefined || data.id == null){
      return this.save(data);
    }
    else{
      return this.patch(data);
    }
  }

  public patch(data: Profil){
    this._loaderService.addLoader();
    delete data.date_creation; delete data.dateCreation;
    

    
    return this._http.patch(environment.API_URL+"profils/"+data.id+"/update", data).map(res => {
      this._loaderService.removeLoader();
      return res as Profil;
    })
  }

  public moveSourceListeProfils(data){
   this._loaderService.addLoader();
   return this._http.post(environment.API_URL+"SourceListeProfils/new", data).map(res => {
      this._loaderService.removeLoader();
      return res;
    })
  }

  public uploadFileForProfil(profil : Profil, file : FormData){
      this._loaderService.addLoader();
      return this._http.upload(environment.API_URL+"profils/"+profil.id+"/cv", file).map(res => {
        this._loaderService.removeLoader();
        return res;
      });
  }

  public deleteCV(ProfilID : number){
      this._loaderService.addLoader();
      return this._http.get(environment.API_URL+"profils/"+ProfilID+"/cv/delete").map(res => {
        this._loaderService.removeLoader();
        return res;
      });
  }


  public deleteRemu(remuId:number){
    this._loaderService.addLoader();
    return this._http.delete(environment.API_URL+"profils/remuneration/"+remuId+"/delete").map(res => {
      this._loaderService.removeLoader();
      return true;
    });
  }

  public update(profil: ProfilSociete) {
      this._loaderService.addLoader();
      return this._http.patch(`${environment.API_URL}SourceListeProfils/update`, profil).map(res => {
        this._loaderService.removeLoader();
        return res as ProfilSociete;
    });
  }

}
