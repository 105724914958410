import { SourceListe } from './source-liste';
import { Profil } from './profil';
export class Etat {


        public id ?: number ;
        public nom : string = null;
        public date_reference ?: string;
        public date_creation ?: string;
        public dateCreation ?: Date;
        public source_liste : SourceListe = null;
        public profil ?: Profil = null;

        constructor(options : {} = null){
    		if (options) Object.assign(this, options);
            this.generateDate();
    	}

        generateDate(){
            if(this.date_creation)  this.dateCreation = new Date(this.date_creation);
        }

        

}
