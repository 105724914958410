export class Search {

        public nom: string;
        public prenom: string;
        public souhait: string;
        public ecole: string;
        public sourcelist: string;
        public fonction: string;
        public societe: string;
        public secteur: number
        public age: string;
        public etat: any;
        public portable: string;
        public parcours: string;
        public commentaires: string;
        public langue: string;


        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
    	  }

}
