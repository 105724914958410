import { DatatableComponent } from '@swimlane/ngx-datatable'; 
import { Directive, HostListener,ElementRef, Input } from '@angular/core'; 
 
@Directive({selector: '[autoSize]'}) 
 
export class AutoSizeDirective { 
 
  @Input() autoSize : any; 
  @Input() refTab : DatatableComponent; 
 
 
  @HostListener('window:resize', ['$event']) 
   
  onResize() { 
    let height =  window.innerHeight; 
    let containerHeight =  height - 110 ; 
 
    if(this.autoSize != undefined){ 
      containerHeight = containerHeight - this.autoSize; 
    } 
    this.el.nativeElement.style.height = containerHeight + "px"; 
 
    if(this.el.nativeElement.tagName != "NGX-DATATABLE"){ 
      this.el.nativeElement.style.overflowY = "auto"; 
      this.el.nativeElement.style.display = "block"; 
    } 
    else{ 
      setTimeout( ()=>{ 
        if(this.refTab != undefined){ 
          this.refTab.recalculate(); 
        } 
      }, 200); 
    } 
   
  } 
 
  constructor(private el: ElementRef) { 
    setTimeout( ()=>{ 
      this.onResize(); 
    },100 ) 
  } 
 
} 
