import { SourceListe } from './../Class/source-liste';
import { Alerte } from './../Class/alerte';
import { Etat } from './../Class/etat';
import { Profil } from './../Class/profil';
import { LoaderService } from './loader.service';
import { HttpClient } from './Tools/http-client.service';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable()
export class EtatAlertService {

    constructor(private _http: HttpClient, private _loaderService: LoaderService) {

    }

    public addEditEtat(etat: Etat) {
        this._loaderService.addLoader();
        if (etat.id != undefined && etat.id != null) {
            return this._http.patch(environment.API_URL + "SourceListeProfilEtats/" + etat.id + "/update", etat).map(res => {
                this._loaderService.removeLoader();
                let data = new Etat(res);
                data.generateDate();
                return data;
            });
        }
        else {
            return this._http.post(environment.API_URL + "SourceListeProfilEtats/new", etat).map(res => {
                this._loaderService.removeLoader();
                let data = new Etat(res);
                data.generateDate();
                return data;
            });
        }
    }

    public getEtats(sourceListe: SourceListe, profil: Profil) {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "SourceListeProfilEtats/"+ sourceListe.id + "/" + profil.id + "/list").map(res => {
            this._loaderService.removeLoader();


            let data = res as Etat[];
            let item : Etat[] = [];
            for (let i = 0; i < data.length; i++) {
                item[i] = new Etat(data[i]);
                item[i].generateDate();
            }
            this._loaderService.removeLoader();
            return item;
        });
    }

    public deleteEtat(etat: Etat) {
        if (etat.id != undefined && etat.id != null) {
            return this._http.delete(environment.API_URL + "SourceListeProfilEtats/" + etat.id + "/delete").map(res => {
                this._loaderService.removeLoader();
                return true;
            });
        }
    }

    public addEditAlert(alert: Alerte) {
        this._loaderService.addLoader();
        if (alert.id != undefined && alert.id != null) {
            return this._http.patch(environment.API_URL + "alertes/" + alert.id + "/update", alert).map(res => {
                this._loaderService.removeLoader();
                return res as Alerte;
            });
        }
        else {
            return this._http.post(environment.API_URL + "alertes/new", alert).map(res => {
                this._loaderService.removeLoader();
                return res as Alerte;
            });
        }
    }

    public getAlerts(profil: Profil) {
        this._loaderService.addLoader();
        return this._http.get(environment.API_URL + "alertes/profil/" + profil.id + "/list").map(res => {
            this._loaderService.removeLoader();
            return res as Alerte[];
        })
    }

    public getUserAlert() {
        return this._http.get(`${environment.API_URL}alertes/list`)
            .map(res => {
                let data = res as Alerte[];
                let response : Alerte[] = [];
                for(let i = 0; i < data.length; i++) {
                    response[i] = new Alerte(data[i]);
                    response[i].generateDate();
                }
                return response;
            });
    }

    public deleteAlert(id: number) {
        this._loaderService.addLoader();
        return this._http.delete(`${environment.API_URL}alertes/${id}/delete`)
            .map(res => {
                this._loaderService.removeLoader()
                return true
            });
    }



}
