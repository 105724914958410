import { SlEventService } from './../source-listes/sl-add-edit/sl-event-manager.service';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { ErrorMessageComponent } from './../error-message/error-message.component';
import { ProfilDialogRefComponent } from './../profil-dialog-ref/profil-dialog-ref.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatSnackBar, MatDialog, MatSnackBarConfig } from '@angular/material';
import { Profil } from './../../Class/profil';
import { ProfilService } from './../../Services/profil.service';
import { SocieteService } from './../../Services/societe.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Societe } from './../../Class/societe';
import { Historic } from './../../Class/historic';
import { HistoricService } from './../historic/historic.service';
import { SecteurService } from './../../Services/secteur.service';
import { Secteur } from './../../Class/secteur';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-societe',
    templateUrl: './societe.component.html',
    styleUrls: ['./societe.component.less'],
    providers: [ProfilService],
    host: { '(window:keydown)': 'hotkeys($event)' },
})
export class SocieteComponent implements OnInit {

    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

    secteurs: Secteur[] = []; //Liste des secteurs
    profils: Profil[] = [new Profil({})];
    tempProfils: Profil[] = [];

    profilSelected: Profil[] = [];

    currentSociete: Societe = new Societe();
    secteurSelected: string;
    tempSecteurs: any;



    constructor(
        private _secteurService: SecteurService,
        private _hs: HistoricService,
        private activatedRoute: ActivatedRoute,
        private _societeService: SocieteService,
        private _profilService: ProfilService,
        private mdSnackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router,
        private _slEvent : SlEventService) {


    }

    hotkeys(event) {
        let key = event.which || event.keyCode;
        if (key == 46) {
            this.deleteProfil();
        }
    }

    filterClient(event) {
    }

    changeSecteurSelected(secteurSelected: Secteur) {
        this.secteurSelected = secteurSelected.nom;
        this.currentSociete.secteur = secteurSelected;
    }

    fiterSecteur(event) { //Input secteur.value
        const val = event.toLowerCase();
        const temp = this.tempSecteurs.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.secteurs = temp;
        //this.table.offset = 0;
        if (event == null || event === undefined || event == "") this.currentSociete.secteur = null;
    }



    dblSelect($event: Profil) {
        let dialogRef = this.dialog.open(ProfilDialogRefComponent, {
            width: "700px",
            height: "850px",
            data: { profil: $event, societe: this.currentSociete },
            backdropClass: 'light-dialog',

        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    newProfil() {
        let dialogRef = this.dialog.open(ProfilDialogRefComponent, {
            width: "700px",
            height: "850x",
            data: { profil: new Profil(), societe: this.currentSociete },
            backdropClass: 'light-dialog',

        });
        dialogRef.afterClosed().subscribe(result => { });
    }

    changeCurrentSociete(societe: Societe) {
        this.router.navigate(['/gestion/societe/' + societe.id]);
    }

    deleteProfil() {
        if (this.profilSelected.length && this.profilSelected[0].id) {
            let item: any = this.profilSelected[0];
            let dialogRf = this.dialog.open(ConfirmDialogComponent, { data: { message: "Supprimer le profil ?" }, backdropClass: 'light-dialog' });
            dialogRf.afterClosed().subscribe(res => {
                if (res) {
                    let id = item.id;
                    let mdSnackBarConfig = new MatSnackBarConfig();
                    mdSnackBarConfig.duration = 2000;
                    mdSnackBarConfig.panelClass = 'success';

                    this._profilService.deleteProfil(+id).subscribe(res => {
                        this.mdSnackBar.open("Le profil à été supprimé ✔", null, mdSnackBarConfig);
                        this.loadProfil(this.currentSociete.id);
                    }, err => { })
                }
            }, err => { });
        }
    }




    openDialogSocieteAlreadyDelete() {
        let dialogRef = this.dialog.open(ErrorMessageComponent, { data: { message: "La société demandé à été archivée" } });

        //Peu import la réponse on ferme l'url en cours
        dialogRef.afterClosed().subscribe((res: void) => this._hs.closeActive(), (err: void) => this._hs.closeActive());
    }

    ngOnInit() {
        //Récupération des Secteurs depuis l'API
        this._secteurService.getSecteurs().subscribe(res => {
            this.secteurs = res;
            this.tempSecteurs = [...this.secteurs];
        });

        this.activatedRoute.params.subscribe(params => {
            const id = params['id'];
            if (id) {
                //Récupération de la société
                this._societeService.getSocieteById(id).subscribe(res => {
                    this.currentSociete = res;
                    (this.currentSociete.hasOwnProperty("secteur") && this.currentSociete.secteur != null) ? this.secteurSelected = this.currentSociete.secteur.nom : "";

                    let link = new Historic({ url: "/gestion/societe/" + res.id, title: res.nom });
                    this._hs.addHistoric(link);

                    if (this.currentSociete.archive === true) {
                        //POP UP + Redirection
                        this.openDialogSocieteAlreadyDelete();
                    }
                    else {
                        //Si j'ai une société récupération des profis rattachée
                        if (res.id) {
                            this.loadProfil(res.id);
                        }
                    }


                });
            }
            else {
                let link = new Historic({ url: "/gestion/societe-add", title: "Nouvelle société" });
                this._hs.addHistoric(link);
            }

        });

        this._slEvent.reloadProfils.subscribe((res:void) => {
            this.loadProfil(this.currentSociete.id);
        })
        if(localStorage.getItem('secteurSelected')){
            this.secteurSelected = localStorage.getItem('secteurSelected');
        }
        if(localStorage.getItem('currentSociete')){
            this.currentSociete = JSON.parse(localStorage.getItem('currentSociete'));
        }

    }

    loadProfil(societeID) {
        this._profilService.getProfilsBySocieteId(societeID).subscribe(res => {
            this.profils = res;
            this.tempProfils = [...this.profils];
            this.table.offset = 0;
        });
    }

    onSubmit() {
        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 2000;
        if (this.currentSociete.hasOwnProperty('id')) {
            this._societeService.patchSociete(this.currentSociete).subscribe(res => {
                this.mdSnackBar.open("Mise à jour ✔", null, mdSnackBarConfig);
            });
        }
        else {
            mdSnackBarConfig.panelClass = 'success';
            this._societeService.postSociete(this.currentSociete).subscribe(res => {
                let item = new Societe(res);
                this.mdSnackBar.open("Ajout d'une société ✔", null, mdSnackBarConfig);
                if (item.id) {
                    this.router.navigate(['/gestion/societe/' + item.id])
                }


            });
        }
        localStorage.setItem('secteurSelected', this.secteurSelected);
        localStorage.setItem('currentSociete', JSON.stringify(this.currentSociete));
    }


    onSort(event) {
        const rows = [...this.profils];
        const sort = event.sorts[0];

        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;


        if (property) { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if(a[prop] == null) a[prop] = "";
                if(b[prop] == null) b[prop] = "";
                if (a[prop].toLowerCase().trim() < b[prop].toLowerCase().trim()) return v1;
                if (a[prop].toLowerCase().trim() > b[prop].toLowerCase().trim()) return v2;
                return 0;
            });
        }



        this.profils = rows;
    }

}
