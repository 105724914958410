import { User } from './../../Class/user';
import { UsersService } from './../../Services/users.service';
import { AuthService } from './../../Services/Tools/auth.service';
import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router'; 


@Component({ 
  selector: 'app-login', 
  templateUrl: './login.component.html', 
  styleUrls: ['./login.component.less'],
  providers:[UsersService]
}) 
export class LoginComponent implements OnInit { 
  errorMessage : string;

  public username : string;
  public password : string;

  constructor(private router: Router, private _auth : AuthService, private _usersService : UsersService) { } 

  ngOnInit() { 
  } 

  onSubmit(form: any){

    let data = {username : form.username,password : form.password}
    if(data.username && data.password){
      this._auth.login(data).subscribe(
      
        data => {
          this._usersService.getUser(data.id).subscribe((res:User) => {
            this._auth.setUser(res);
          });
          (data) ? this.router.navigate(['/gestion/source-liste']) : this.displayError();
        },
        err => {
          this.displayError();
        }
      );    
    }
  } 

  displayError(){
      this.errorMessage = "Login ou mot de passe incorrecte."
      setInterval( ()=> this.errorMessage = "", 5000 ); //After 5s ce message are trash
  }
  
} 