import { Etat } from './../../Class/etat';
import { Router } from '@angular/router';
import { SlEventService } from './../source-listes/sl-add-edit/sl-event-manager.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { SourceListeSociete } from './../../Class/source-liste-societe';
import { ProfilResearch } from './../../Class/profil-research';
import { Search } from './../../Class/search';
import { Component, OnInit, HostListener, Input, ViewChild } from '@angular/core';
import { SecteurService } from "./../../Services/secteur.service";
import { Secteur } from "./../../Class/secteur";
import { Historic } from "./../../Class/historic";
import { ProfilService } from "./../../Services/profil.service";
import { SourceListeService } from "./../../Services/source-liste.service";
import { ResearchService } from "./../../Services/research.service";
import { environment } from "./../../../environments/environment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { HistoricService } from '../historic/historic.service';



@Component({
    selector: 'app-research',
    templateUrl: './research.component.html',
    styleUrls: ['./research.component.less'],
    providers: [ProfilService, SourceListeService, ResearchService]
})

export class ResearchComponent implements OnInit {

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.getProfiles();
        }
    }
    @Input() popUpMode: boolean;
    @Input() societeSourceListe: SourceListeSociete;

    @ViewChild(DatatableComponent, {static: false}) tableselect: DatatableComponent;


    //Profils for Sl
    public profilsSL: any[] = [];
    public profilsSelected: ProfilResearch[] = [];
    // Liste secteur
    public secteurs: Secteur[];
    // Liste profils
    public profils: ProfilResearch[] = [];
    // Etats
    public etatListe: string[];
    // Champs de recherche
    public search = new Search();

    constructor(
        private _secteurService: SecteurService,
        private _hs: HistoricService,
        private _sourceListeService: SourceListeService,
        private _researchService: ResearchService,
        private _slEvent: SlEventService,
        private mdSnackBar: MatSnackBar,
        private router: Router
    ) { }


    addForSociete() {
      console.log(this.profilsSelected);

        if(this.profilsSelected.length){
          let exist = false;
          this.profilsSL.map(item => {
            if(item['profil']['id'] == this.profilsSelected[0]['profil']['id']){
              exist = true;
            }
          });
          if(!exist){
            this.profilsSL.push(new ProfilResearch(this.profilsSelected[0]));
            this.profilsSL = [...this.profilsSL];
          }
        }
        this.profilsSelected = [];
        this.profilsSelected = [...this.profilsSelected];
        this.tableselect.recalculate();
    }

    moveProfilInSociete() {
        this._slEvent.set_profilsForSourceListe(this.profilsSL);
        this._slEvent.run_reloadArbo();
    }

    getProfiles() {
        localStorage.setItem('search', JSON.stringify(this.search));
        for(var key in this.search)
        {
            if(this.search[key] == "") delete this.search[key];
        }
        if(Object.keys(this.search).length) {
            let mdSnackBarConfig = new MatSnackBarConfig();
            mdSnackBarConfig.duration = 3000;
            mdSnackBarConfig.panelClass = 'success';

          this._researchService.getProfiles(this.search).subscribe((data: ProfilResearch[]) => {
            if(this.router.routerState.snapshot.url == "/gestion/research"){

                let profils = [];
                for (let index = 0; index < data.length; index++) {
                    let item = data[index];


                    if(item.source_liste.archive == false){
                        profils.push(item);
                    }

                }
                this.profils = profils;
                localStorage.setItem('profils', JSON.stringify(this.profils));
            }
            else{
                this.profils = data;
                localStorage.setItem('profils', JSON.stringify(this.profils));
            }
            // this.profils = data

        },
            err => console.error(err),
            () => this.mdSnackBar.open("Recherche terminée", null, mdSnackBarConfig));
        }
        else{
          this.clearResults();
        }
    }

    printResearch(){

        let url =  environment.API_URL + "profils/recherche/v1/download";

        let i = 0;
        for(var key in this.search)
        {
            if(this.search[key] == ""){
                 delete this.search[key];
            }
            else{
                if(!i){
                    url = url+"?"+key+"="+this.search[key]
                }
                else{
                    url = url+"&"+key+"="+this.search[key]
                }
            }
            i++;
        }
        if(i) window.open(url,'_blank');
    }


  clearResults(){
    this.profils = [];
    localStorage.setItem('profils', JSON.stringify([]));
  }

  clearResearch(){
    localStorage.setItem('search', JSON.stringify(new Search()));
    this.search = new Search();
  }

  inputReset() {
    this.clearResearch();
    this.clearResults();
  }


    setProfilResearch() {


        if (this.profilsSelected.length && this.router.routerState.snapshot.url == "/gestion/research") {
            let data = this.profilsSelected[0];
            this.router.navigate(['/gestion/source-liste',
                data.source_liste.id,
                data.societe.id,
                data.profil.id
            ]);
        }
    }


    ngOnInit() {
        // Historique de recherche
        if (this.popUpMode == undefined || this.popUpMode == null) {
            let link = new Historic({ url: "/gestion/research", title: "Recherche" });
            this._hs.addHistoric(link);
        }
        // Récupération des Secteurs depuis l'API
        this._secteurService.getSecteurs().subscribe(res => {
            this.secteurs = res;
        });
        // Récupération des etats
        this.etatListe = this._sourceListeService.getEtatList();

        const storedSearch = JSON.parse(localStorage.getItem('search'));
        const storedProfils = JSON.parse(localStorage.getItem('profils'));

        if(storedProfils){
            this.search = storedSearch;
            this.profils = storedProfils;
        }
    }


    onSort(event) {
        const rows = [...this.profils];
        const sort = event.sorts[0];

        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;


        if (property == "nom" || property == "prenom" || property == "age" || property == "tel_portable" || property == "fonction" || property == "fonction"
        || property == "souhaits" || property == "parcours" || property == "commentaire" || property == "langues" || property == "diplomes" || property == "remuneration") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if(a.profil[prop] == null) a.profil[prop] = "";
                if(b.profil[prop] == null) b.profil[prop] = "";
                if (a.profil[prop].toLowerCase() < b.profil[prop].toLowerCase()) return v1;
                if (a.profil[prop].toLowerCase() > b.profil[prop].toLowerCase()) return v2;
                return 0;
            });
        }


        if(property == "latestProfilEtat"){
            rows.sort((a, b) => {

                if(a.latestProfilEtat == null) { a.latestProfilEtat = new Etat({id: null, nom : ""}) }
                if(b.latestProfilEtat == null) { b.latestProfilEtat = new Etat({id: null, nom : ""}) }

                if (a.latestProfilEtat.nom.toLowerCase() == b.latestProfilEtat.nom.toLowerCase())return 0;
                if (a.latestProfilEtat.nom.toLowerCase() < b.latestProfilEtat.nom.toLowerCase()) return v1;
                if (a.latestProfilEtat.nom.toLowerCase() > b.latestProfilEtat.nom.toLowerCase()) return v2;
            });
        }

        if (property == "source_liste") {
            rows.sort((a, b) => {
                if (a.source_liste.titre < b.source_liste.titre) return v1;
                if (a.source_liste.titre > b.source_liste.titre) return v2;
                return 0;
            });
        }

         if (property == "societe") {
            rows.sort((a, b) => {
                if (a.societe.nom < b.societe.nom) return v1;
                if (a.societe.nom > b.societe.nom) return v2;
                return 0;
            });
        }
        this.profils = rows;
    }

}
