import { Profil } from './../../Class/profil';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-profil-dialog-ref',
  templateUrl: './profil-dialog-ref..component.html',
  styles: ['']
})
export class ProfilDialogRefComponent implements OnInit {

  public profil: Profil;

  /**
   * Ce component est un composant tunel entre un composant enfant de première instance et un composant Material de type DialogRef
   * 
   */

  constructor(public dialogRef: MatDialogRef<ProfilDialogRefComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.profil = this.data;
  }

}
