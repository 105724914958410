import { ConfirmDialogComponent } from './../../confirm-dialog/confirm-dialog.component';
import { MatSnackBar, MatDialog, MatSnackBarConfig } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Client } from './../../../Class/client';
import { ClientService } from './../../../Services/client.service';
import { SourceListe } from './../../../Class/source-liste';
import { SourceListeService } from './../../../Services/source-liste.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoricService } from '../../historic/historic.service';
import { Historic } from '../../../Class/historic';
import { Router } from '@angular/router';




@Component({
    selector: 'app-sl-home',
    templateUrl: './sl-home.component.html',
    styleUrls: ['./sl-home.component.less'],
    providers: [SourceListeService],
    host: { '(window:keydown)': 'hotkeys($event)' },
})
export class SlHomeComponent implements OnInit {

    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;


    //ngx-datatable => source-listes
    sourceListes: SourceListe[] = [new SourceListe({})];
    //ngx-datatable => Selected
    sourceListe: SourceListe[] = [];
    tempSourceListes: any = []; //For Research


    //Select options => Clients
    clients: Client[];
    //Select option => Selected
    client: Client = new Client();
    //Client name for ([Model]) not Class Client
    clientSelected: string = "";
    tempClients: any; //For Research

    //Research basique format
    intitule: string = "";
    reference: string = "";
    dateDebut: any;
    dateFin: any;

    //Etats
    etatListe: string[];
    etatSelected: string;



    constructor(private _clientService: ClientService, private _sourceListService: SourceListeService, private mdSnackBar: MatSnackBar, public dialog: MatDialog, private router: Router) {


    }

    hotkeys(event) {
        let key = event.which || event.keyCode;
        if (key == 46) {
            this.delete();
        }
    }


    //CLIENTS FILTERS
    filterClient(event: any) { //Input secteur.value
        const val = event.toLowerCase();
        const temp = this.tempClients.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.clients = temp;

        this.makeGlobalResearch();
    }

    changeClientSelected(item: Client) {
        this.client = item;
        this.clientSelected = item.nom;
    }

    duplicate() {
        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 2000;
        mdSnackBarConfig.panelClass = 'success';

        if (this.sourceListe.length) {
            let dialogRf = this.dialog.open(ConfirmDialogComponent, { data: { message: "Dupliquer la source liste ?" }, backdropClass: 'light-dialog' });
            dialogRf.afterClosed().subscribe(res => {
                if (res) {
                    let id = this.sourceListe[0].id;
                    this._sourceListService.duplicateSourceListe(+id).subscribe(res => {
                        this.mdSnackBar.open("La source liste à été dupliquée ✔", null, mdSnackBarConfig);
                        if (res.id) {
                            this.sourceListes.unshift(res);
                            this.table.offset = 0;
                        }

                    }, err => { })
                }
            }, err => { });
        }
    }

    delete() {
        if (this.sourceListe.length) {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: "Supprimer la source liste ?" }, backdropClass: 'light-dialog' });
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    let item: any = this.sourceListe[0];

                    let mdSnackBarConfig = new MatSnackBarConfig();
                    mdSnackBarConfig.duration = 2000;
                    mdSnackBarConfig.panelClass = 'success';

                    this._sourceListService.deleteSourceListe(+item.id).subscribe(res => {
                        this.mdSnackBar.open("La source liste à été supprimée ✔", null, mdSnackBarConfig);
                        this.loadSourceListe();
                    }, err => { });
                }
            }, (err: void) => {  });
        }
    }



    makeGlobalResearch() {
        const temp = this.tempSourceListes.filter(d => {
            return (this.intitule === "" || d.titre.toLowerCase().indexOf(this.intitule.toLowerCase()) !== -1) &&
                (this.reference === "" || d.reference.toLowerCase().indexOf(this.reference.toLowerCase()) !== -1) &&
                ((this.dateDebut === undefined || this.dateDebut == null || this.dateDebut == "") || (new Date(this.dateDebut) <= new Date(d.date_debut) && d.date_debut != null)) &&
                ((this.dateFin === undefined || this.dateFin == null || this.dateFin == "") || (new Date(this.dateFin) >= new Date(d.date_fin) && d.date_fin != null)) &&
                ((this.clientSelected === undefined || this.clientSelected === "") || (d.client != null && d.client.nom.toLowerCase() == this.clientSelected.toLowerCase())) &&
                ((this.etatSelected === undefined || this.etatSelected === null || this.etatSelected == "") || d.etat == this.etatSelected)
        });
        this.sourceListes = temp;
    }

    loadSourceListe(){
        this.sourceListes = [new SourceListe({})];
        this._sourceListService.getSourcesListes().subscribe(res => {
            this.sourceListes = res;
            this.tempSourceListes = [...res];
            this.table.offset = 0;
        });
    }

    ngOnInit() {
        //Récupération des Etats de sourceListe depuis une constante dans SourceListeService
        this.etatListe = this._sourceListService.getEtatList();
        //Récupération des sourceListes depuis l'API
        this.loadSourceListe();
        //Récupération des clients depuis l'API
        this._clientService.getClients().subscribe(res => {
            this.clients = res;
            this.tempClients = [...this.clients];
        });
    }

    dblSelect(item : SourceListe) {
        this.router.navigate(["/gestion/source-liste/"+item.id]);
    }



    onSort(event) {
        const rows = [...this.sourceListes];
        const sort = event.sorts[0];

         let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;

        if (property == "titre" || property == "reference" || property == "commentaire" || property == "etat") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if(a[prop] == null) a[prop] = "";
                if(b[prop] == null) b[prop] = "";
                if (a[prop].toLowerCase() < b[prop].toLowerCase()) return v1;
                if (a[prop].toLowerCase() > b[prop].toLowerCase()) return v2;
                return 0;
            });
        }

        if (property == "dateDebut" || property == "dateFin" || property == "dateModification") {
            let prop = property;
            rows.sort((a, b) => {

                let date1 = new Date(a[prop]); if(a[prop] == null || a[prop] == "") date1 = new Date("1800-01-01");
                let date2 = new Date(b[prop]); if(b[prop] == null || b[prop] == "") date2 = new Date("1800-01-01");

                if (date1 < date2) return v1;
                if (date1 > date2) return v2;
                return 0;
            });
        }

        if (property == "client") {
            rows.sort((a, b) => {
                if(a.client == null){a.client = new Client({nom : ""});}
                if(b.client == null){b.client = new Client({nom : ""});}

                if (a.client.nom.toLowerCase() < b.client.nom.toLowerCase()) return v1;
                if (a.client.nom.toLowerCase() > b.client.nom.toLowerCase()) return v2;
                return 0;
            });
        }
        this.sourceListes = rows;
    }

}

