import { ActivatedRoute, Params } from '@angular/router';
import { Alerte } from './../../../../../Class/alerte';
import { EtatAlertService } from './../../../../../Services/etat-alert.service';
import { Etat } from './../../../../../Class/etat';
import { environment } from './../../../../../../environments/environment';
import { ProfilSociete } from './../../../../../Class/profil-societe';
import { SourceListe } from './../../../../../Class/source-liste';
import { ProfilService } from './../../../../../Services/profil.service';
import { LangueDialogComponent } from './../../../../langue-dialog/langue-dialog.component';
import { Remuneration } from './../../../../../Class/remuneration';
import { RemunerationDialogComponent } from './../../../../remuneration-dialog/remuneration-dialog.component';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Profil } from './../../../../../Class/profil';
import { SlEventService } from './../../sl-event-manager.service';
import { Societe } from './../../../../../Class/societe';
import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs/Subscription';
import { Location } from "@angular/common";
import { HistoricService } from './../../../../historic/historic.service';
import { ConfirmDialogComponent } from './../../../../confirm-dialog/confirm-dialog.component';




@Component({
    selector: 'app-tab-profil',
    templateUrl: './tab-profil.component.html',
    styleUrls: ['./tab-profil.component.less'],
    providers: [ProfilService, EtatAlertService]
})
export class TabProfilComponent implements OnInit {
    @ViewChild(DatatableComponent, {static: false}) tableEtat: DatatableComponent;
    @ViewChild(DatatableComponent, {static: false}) tableAlert: DatatableComponent;
    @Input() profilIn: any; //{{profil , societe }}

    currentProfil: Profil = new Profil();
    cpt: Profil = new Profil(); //Profil temp uniquement pour l'association de société
    currentSociete: Societe;
    currentSourceListe: SourceListe;
    public params: Params;
    private subscriptions: Array<Subscription> = [];
    //Etats
    ngxColonnes = [  //{prop : "property", name : "label"}
        { prop: "nom", name: "Etat" },
        { prop: "date_reference", name: "Date d'enregistrement" },
        { prop: "source_liste.titre", name: "Source Liste" }
    ];
    labelEtats = [
        "Approché le",
        "Message le",
        "A rappeler le",
        "Ouvert",
        "A quitté la société",
        "Pas intéressé",
        "Rdv le",
        "RDV annulé",
        "Ne vient pas au RDV",
        "Pl après RDV",
        "Ne convient pas",
        "Dossier",
        "Ne suit pas",
        "Présenté au client le",
        "Proposition refusée",
        "Embauché",
        "Eliminé par MVM le",
        "Eliminé par le client le",
        "Etat personnalisé..."]
    newEtat: Etat = new Etat({ nom: this.labelEtats[0] });
    customEtatNom: string = "";
    public etats = [];
    etatSelected = [];
    //Alerts
    public alerts: Alerte[] = [];
    alertSelected = [];
    newAlert: Alerte = new Alerte({ titre: "" });
    date: string = this.setCurrentDate();
    time: string = this.setCurrentTime();
    alertDate: string = this.setCurrentDate();

    //Gestion de la sauvegarde automatique
    _timeout: any = null;

    constructor(
        private _slEvent: SlEventService,
        public dialog: MatDialog,
        public _profilService: ProfilService,
        public _etatAlertService: EtatAlertService,
        public _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private _historicService: HistoricService
    ) { }


    updateReelTime(event){
        if(this._timeout){   //if there is already a timeout in process cancel it
            window.clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout( () => {
                this._timeout = null;
                this.saveProfil();
        }, 500 );


    }

    setCurrentDate() {
        let date = new Date();

        let yyyy = date.getFullYear();
        let mm = "" + date.getMonth(); mm = "" + (+mm + 1);
        let dd = "" + date.getDate();
        if (+dd < 10) dd = "0" + dd;
        if (+mm < 10) mm = "0" + mm;

        return `${yyyy}-${mm}-${dd}`;
    }
    setCurrentTime(){
        return "09:00";
    }
    associate() {
        this.cpt.societe = this.currentSociete;
        this._profilService.patch(this.cpt).subscribe(res => {
            this.currentProfil.societe = this.cpt.societe;
            this._slEvent.run_reloadProfil();
        }, err => { });
    }
    removeAssociate() {
        this.cpt.societe = null;
        this._profilService.patch(this.cpt).subscribe(res => {
            this.currentProfil.societe = this.cpt.societe;
        });
    }
    fileChange(event: any) {
        if (this.currentProfil.id != undefined && this.currentProfil.id != null) {
            if (event.target.files.length) {
                let data = event.target.files[0];
                if (this.acceptFileExtension(this.getFileExtension(data.name))) {
                    if (this.goodFileSize(+data.size)) {
                        let file = new FormData();
                        file.append('cv_filename', data);
                        this._profilService.uploadFileForProfil(this.currentProfil, file).subscribe((res: Profil) => {
                            this.currentProfil.cv_filename = res.cv_filename;
                            this.cpt.cv_filename = res.cv_filename;
                        });
                    }
                    else {
                        alert("Le fichier est trop volumineux, max 10Mo");
                    }
                }
                else {
                    alert("Extension de fichier non autorisé : " + this.getFileExtension(data.name));
                }
            }
        }
        else {
            alert("Vous devez d'abord enregistrer le profil pour pouvoir envoyer un CV");
        }
    }//END fileChange(event : any)
    goodFileSize(size: number): boolean {
        let accept = false,
            sizeAuth = 10485760; //Max 10Mo
        if (size <= sizeAuth) accept = true;
        return accept;
    }//END goodFileSize(size:number)
    getFileExtension(filename: string): string {
        let parts = filename.split('.');
        return parts[parts.length - 1];
    }//END getFileExtension(filename: string)
    acceptFileExtension(extension: string): boolean {
        let accept = false;
        switch (extension) {
            case 'doc':
            case 'pdf':
            case 'docx':
                accept = true;
        }
        return accept;
    }//END acceptFileExtension(extension: string)
    viewCV() {
        let url: string = environment.API_URL + "profils/{1}/download";
        url = url.replace("{1}", "" + this.currentProfil.id);
        window.open(url, '_blank');
    }//END viewCV()
    openDialogRemuneration() {
        let dialogRef = this.dialog.open(RemunerationDialogComponent, { backdropClass: 'light-dialog' });
        dialogRef.afterClosed().subscribe(
            (res: Remuneration) => {
                console.log(res);
                 res['date_creation'] = new Date(res.date_creation) as any;
                 res['date_modification'] =  new Date(res.date_creation) as any;
                 res['date_remuneration'] =  new Date(res.date_creation) as any;
                 console.log(res);
                if (res) {
                    this.currentProfil.remunerations.push(res);
                    this.currentProfil.remuneration = res.remuneration;
                    this.saveProfil();
                }
            },
            err => { }
        );
    }//END openDialogRemuneration()
    openDialogRemunerationEdit(remu){
        let dialogRef = this.dialog.open(RemunerationDialogComponent, { backdropClass: 'light-dialog', data : remu });
        dialogRef.afterClosed().subscribe( (res: Remuneration) => {
          console.log(res);
                 res.date_creation = res.date_creation;
                 res.date_modification = new Date();
                 res.date_remuneration = res.date_remuneration;
                if (res) {
                    for(let i = 0; i < this.currentProfil.remunerations.length; i++){
                        if(res.id == this.currentProfil.remunerations[i].id){
                            this.currentProfil.remunerations[i] = res;
                            console.log(this.currentProfil.remunerations[i]);
                            this.saveProfil();
                        }
                    }
                }
            },
            err => { }
        );
    }
    deleteRemu(remuId){

        let id = +remuId;
        let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Supprimer la rémunération ?` }, backdropClass: "light-dialog" });

        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 200;
        mdSnackBarConfig.panelClass = 'success';

        dialogRef.afterClosed().subscribe(res=> {
            if (res) {
                this._profilService.deleteRemu(remuId).subscribe(res => {
                    for(let i = 0; i < this.currentProfil.remunerations.length; i++){
                        if(+remuId == +this.currentProfil.remunerations[i].id){
                            this.currentProfil.remunerations.splice(i,1);
                            this._snackBar.open("La rémunération a bien été supprimmée", "Fermer", mdSnackBarConfig);
                            break;
                        }
                    }
                });
            }
        });



    }
    openDialogLangues() {
        let dialogRef = this.dialog.open(LangueDialogComponent);
        dialogRef.afterClosed().subscribe(
            (res: string) => {
                if (res) {
                    this.currentProfil.langues += res;
                }
            },
            err => { }
        );
    }//END openDialogLangues()
    saveProfil() {
        if (this.popUpMode) this.currentProfil.societe = this.currentSociete;
        this._profilService.patchAndSave(this.currentProfil, this.currentSociete).subscribe(res => {

            this.currentProfil.remuneration = res.remuneration;
            this.currentProfil.remunerations = res.remunerations;
            if ((this.currentProfil.id == null || this.currentProfil.id == undefined) && res.id && this.currentSourceListe != undefined) {
                let data = {
                    source_liste: this.currentSourceListe.id,
                    societe: this.currentSociete.id,
                    profil: res.id
                }
                this._profilService.moveSourceListeProfils(data).subscribe((res: ProfilSociete) => {
                    this._slEvent.run_reloadProfil();
                    this._slEvent.run_reloadArbo();
                });
                this.currentProfil.id = res.id;

                let url = "/gestion/source-liste/" + this.currentSourceListe.id + "/" + this.currentSociete.id + "/" + res.id;
                this.location.replaceState(url);
                this._historicService.changeUrl(url);
            }
            else {
                if (this.currentProfil.id == null && this.currentSourceListe == undefined) {
                    //pas de déplacement en source liste
                    this._slEvent.run_reloadProfil();
                }
                else {
                    this.cpt = res;
                }
            }

        }, err => {
        })
    }//END saveProfil()
    deleteCV() {
        let id = +this.currentProfil.id;
        let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Supprimer le CV ?` }, backdropClass: "light-dialog" });

        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 2000;
        mdSnackBarConfig.panelClass = 'success';

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this._profilService.deleteCV(id).subscribe(res => {
                    this.currentProfil = res;
                    this._snackBar.open("Le CV a bien été supprimé", "Fermer", mdSnackBarConfig);
                    this._slEvent.run_reloadProfil();
                });
            }
        });

    }
    addNewEtat() {


        if (this.currentProfil.id != null && this.currentProfil.id != undefined) {
            const data = new Etat();
            if (this.newEtat.nom == this.labelEtats[this.labelEtats.length - 1]) {
                data.nom = this.customEtatNom;
            }
            else {
                data.nom = this.newEtat.nom;
            }

            if (data.nom == 'Approché le' || data.nom == 'Message le' || data.nom == 'A rappeler le' || data.nom == 'Rdv le' || data.nom == 'Présenté au client le' || data.nom == 'Eliminé par MVM le' || data.nom == 'Eliminé par le client le') {
                data.date_reference = this.alertDate;
            }
            else { data.date_reference = null }
            if (this.newEtat.id != undefined && this.newEtat.id != null && this.etatSelected.length) {
                data.id = this.etatSelected[0].id;
            }

            data.source_liste = this.currentSourceListe;
            data.profil = this.currentProfil;
            this._etatAlertService.addEditEtat(data).subscribe(res => {
                this.customEtatNom = "";
                this.loadEtat();
                this._slEvent.run_reloadProfil();
            }
            );
        }
        else {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: "Vous devez selectionner un profil" }, backdropClass: "light-dialog" });
        }

    }//END addNewEtat()
    loadEtat() {
        this._etatAlertService.getEtats(this.currentSourceListe, this.currentProfil).subscribe((res: Etat[]) => {
            const data: any[] = [];
            for (let i = 0; i < res.length; i++) { data.push(res[i]); data[i].$$index = i; }
            this.etats = data;

            if(!this.etats.length){
                this.etats = [new Etat({})];
            }
            this.etats = [...this.etats];
        });
    }
    setEtatForEdit() {
        if (this.etatSelected.length) {
            const rows = this.etatSelected;
            const data = rows[0];
            let custom = true;
            let etat = new Etat();
            for (let i = 0; i < this.labelEtats.length; i++) {
                if (data.nom == this.labelEtats[i]) {
                    custom = false;
                    break;
                }
            }
            if (custom) {
                this.customEtatNom = data.nom;
                this.newEtat.nom = this.labelEtats[this.labelEtats.length - 1];
            }
            else {
                this.newEtat.nom = rows[0].nom;
            }
            this.newEtat.id = rows[0].id;

            let dateAlert = new Date(rows[0].date_reference);

            let yyyy = dateAlert.getFullYear();
            let mm = "" + dateAlert.getMonth(); mm = "" + (+mm + 1);
            let dd = "" + dateAlert.getDate();
            if (+dd < 10) dd = "0" + dd;
            if (+mm < 10) mm = "0" + mm;
            this.alertDate = `${yyyy}-${mm}-${dd}`;

            this.newEtat.date_reference = rows[0].date_reference;
        }



    }
    setNewEtat() {
        this.newEtat = new Etat({ nom: this.labelEtats[0] });
        this.alertDate = this.setCurrentDate();
        this.etatSelected = [];
    }
    loadAlert() {
        //this.tableEtat.offset = 0;
        this._etatAlertService.getAlerts(this.currentProfil).subscribe((res: Alerte[]) => {

            const data: any[] = [];
            for (let i = 0; i < res.length; i++) { data.push(res[i]); data[i].$$index = i; }
            this.alerts = data;
            if(!this.alerts.length){
                this.alerts = [new Alerte({})];
            }
            this.alerts = [...this.alerts];
        });
    }
    deleteEtat() {
        const data = this.etatSelected;
        if (data.length) {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Confirmer la suppression de l'état ?` }, backdropClass: "light-dialog" });

            let mdSnackBarConfig = new MatSnackBarConfig();
            mdSnackBarConfig.duration = 2000;
            mdSnackBarConfig.panelClass = 'success';

            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this._etatAlertService.deleteEtat(data[0]).subscribe(res => {
                        this._snackBar.open("L'état a été supprimé", "Fermer", mdSnackBarConfig);
                        this.customEtatNom = '';


                        this.etatSelected = [];
                        for(let i = 0; i < this.etats.length; i++){
                            if(this.etats[i].id == data[0].id){
                                this.etats.splice(i,1);
                                if(!this.etats.length){ this.etats = [new Etat({})]}
                                break;
                            }
                        }

                        this._slEvent.run_reloadProfil();
                    })
                }
            });
        }
        else {
            this.dialog.open(ConfirmDialogComponent, { data: { message: "Vous devez selectionner un état pour pouvoir le supprimer." } });
        }

    }//deleteEtat()

    deleteAlert() {

        const data = this.alertSelected;
        if (data.length) {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Confirmer la suppresion de l'alerte ?` }, backdropClass: "light-dialog" });

            let mdSnackBarConfig = new MatSnackBarConfig();
            mdSnackBarConfig.duration = 2000;
            mdSnackBarConfig.panelClass = 'success';

            dialogRef.afterClosed().subscribe(res=> {
                if (res) {
                    this._etatAlertService.deleteAlert(this.alertSelected[0].id).subscribe(res => {
                        this._snackBar.open("L'alerte a été supprimer", "Fermer", mdSnackBarConfig);
                        this.alertSelected = [];
                        this.newAlert = new Alerte({});
                        this._slEvent.run_reloadProfil();
                        for(let i = 0; i < this.alerts.length; i++){
                            if(this.alerts[i].id == data[0].id){
                                this.alerts.splice(i,1);
                                if(!this.alerts.length){ this.alerts = [new Alerte({})]}
                                break;
                            }
                        }




                    });
                }
            })
        }
        else {
            this.dialog.open(ConfirmDialogComponent, { data: { message: "Vous devez selectionner une alarme pour pouvoir la supprimer." } });
        }


    }//deleteEtat()
    editAlert() {
        if (this.alertSelected.length) {
            this.newAlert = this.alertSelected[0];
            let date = new Date(this.newAlert.date_echeance);

            let mi = "" + date.getMinutes(); if (+mi < 10) mi = "0" + mi;
            let ho = "" + date.getHours(); if (+ho < 10) ho = "0" + ho;
            this.time = "" + ho + ":" + mi;
            //Set date for ngModel
            let m = "" + date.getMonth(); m = "" + (+m + 1);
            if (+m < 10) m = "0" + m;
            let d = "" + date.getDate();
            if (+d < 10) d = "0" + d;
            this.date = "" + date.getFullYear() + "-" + m + "-" + d;
        }

    }
    saveAlert() {
        let data = this.newAlert;
        data.source_liste = this.currentSourceListe;
        data.profil = this.currentProfil;
        data.societe = this.currentSociete;
        if (data.date_echeance == "Invalid Date") data.date_echeance = null;

        let date = new Date(this.date);
        if (this.time != undefined && this.time != null && this.time != "") {

            let str = this.time.split(":");
            date.setHours(+str[0], +str[1]);


            data.date_echeance = "" + date;
        }
        else {
            data.date_echeance = "" + date;
        }

        this._etatAlertService.addEditAlert(data).subscribe(res => {
            this.loadAlert();
        });
    }
    popUpMode() {
        if (this.profilIn != undefined && this.profilIn != null) { return true } else { return false }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
    ngOnInit() {

        // window.setTimeout(()=>{
        //     window.dispatchEvent(new Event('resize'));
        // },2000);

        if (this.profilIn != undefined && this.profilIn != null) this.currentProfil = this.profilIn.profil, this.currentSociete = this.profilIn.societe;

        this.subscriptions.push(
            this._slEvent.profilAddEdit.subscribe((res: Profil) => {
                this.newAlert = new Alerte({});
                this.newEtat = new Etat({});
                this.date = this.setCurrentDate();
                this.time = "09:00";
                this.alerts = [new Alerte({})];
                this.etats = [new Alerte({})];
                this.alertSelected = [];
                this.etatSelected = [];
                this.currentProfil = res;
                this.cpt = res;

                if (res.id != null && res.id != undefined && this.currentSociete.dossier == false) {
                    this.loadEtat();
                    this.loadAlert();
                }
            }),
            this._slEvent.societeForProfil.subscribe((res: Societe) => {
                this.currentSociete = res;

            }),
            this._slEvent.sourceListeAddEdit.subscribe((res: SourceListe) => {
                this.currentSourceListe = res;


            })
        );








    }//END ngOnInit()
    onSort(event) {

        const rows = [...this.etats];
        const sort = event.sorts[0];

        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;
        if (property == "source_liste") {
            rows.sort((a, b) => {
                if (a.source_liste.titre < b.source_liste.titre) return v1;
                if (a.source_liste.titre > b.source_liste.titre) return v2;
                return 0;
            });
        }
        if (property == "nom") {
            rows.sort((a, b) => {
                if (a.nom < b.nom) return v1;
                if (a.nom > b.nom) return v2;
                return 0;
            });
        }
        if (property == "dateCreation") {
            rows.sort((a, b) => {
                if (new Date(a.date_creation) < new Date(b.date_creation)) return v1;
                if (new Date(a.date_creation) > new Date(b.date_creation)) return v2;
                return 0;
            });
        }
        this.etats = rows;
    }
    onSortAlert(event) {

        const rows = [...this.alerts];
        const sort = event.sorts[0];

        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;

        if (property == "titre") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if (a[prop] < b[prop]) return v1;
                if (a[prop] > b[prop]) return v2;
                return 0;
            });
        }

        if (property == "source_liste") {
            rows.sort((a, b) => {
                if (a.source_liste.titre < b.source_liste.titre) return v1;
                if (a.source_liste.titre > b.source_liste.titre) return v2;
                return 0;
            });
        }
        if (property == "date_echeance") {
            rows.sort((a, b) => {
                if (new Date(a.date_echeance) < new Date(b.date_echeance)) return v1;
                if (new Date(a.date_echeance) > new Date(b.date_echeance)) return v2;
                return 0;
            });
        }

        if (property == "utilisateur") {
            rows.sort((a, b) => {
                if (a.utilisateur.login < b.utilisateur.login) return v1;
                if (a.utilisateur.login > b.utilisateur.login) return v2;
                return 0;
            });
        }


        this.alerts = rows;
    }

}
