import { LoaderService } from './../loader.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';  // Updated for Angular 6/RxJS 6
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { HttpClient as LOL, HttpHeaders } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';


 

@Injectable()
export class HttpClient {

    private colorUrlGet = 'background:#03A9F4; color:#fff';
    private colorUrlResponse = 'background:#4CAF50; color:#fff';
    private colorUrlResponseError = 'background:#E91E63; color:#fff';

    private mdSnackBarConfigError = new MatSnackBarConfig();

    @LocalStorage() private token;

    constructor(private http: LOL, public snackBar: MatSnackBar, private _loaderService: LoaderService, private router: Router ) {
        this.mdSnackBarConfigError.duration = 5000;
        this.mdSnackBarConfigError.panelClass = 'error';
     }

    get(url) {
        //HEADER CONFIG
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }
              

        // console.log("[ GET ]  " + url);
        // console.log("TOKEN IS  " +  this.token);
        return this.http.get(url,  {headers : headers})
        .pipe(
            map(res => {
                if(res != null){
                    this.checkExpirationToken(res['status']);
                    this.setToken(res);
                    return res;
                }
                else{
                    return res;
                }
            }),
            catchError((res: any, caught: Observable<any>) => {
                console.log("%c [ GET ]  " + url, this.colorUrlResponseError);
                console.log(res);
                this.snackBar.open("❗ Erreur serveur", "", this.mdSnackBarConfigError);

                this.checkExpirationToken(res['status']);
                this._loaderService.removeLoader();
                return throwError(caught);
            })
        );
    }

    post(url, data) {
        //HEADER CONFIG
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        return this.http.post(url, data,  {headers : headers})
        .pipe(
            map(res => {
                if(res != null){
                    this.checkExpirationToken(200);
                    this.setToken(res);
                    
                }
                return res;
            }),
            catchError((res: any, caught: Observable<any>) => {
                console.log("%c [ POST ]  " + url, this.colorUrlResponseError);
                this.snackBar.open("❗ Erreur serveur", "", this.mdSnackBarConfigError);
                this.checkExpirationToken(res['status']);
                this._loaderService.removeLoader();
                return throwError(caught);
            })
        );
    }




    postDownload(url, data) {
        
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' })
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        // Version précédente :
        // return this.http.post(url, data, { headers: headers, method: 'POST', responseType: 'blob' }).subscribe...
        // Pourquoi remettre method: 'POST' ?
        // TODO : Tester si fonctionne en Angular 6
        return this.http.post(url, data, { headers: headers, responseType: "blob" }).subscribe(res => {
            var reader = new FileReader();
            var out = new Blob([res], {type: 'application/octet-binary'});
            reader.onload = function(e){
                window.location.href = reader['result']+"";
            }
            reader.readAsDataURL(out);

            return res;
        } )


    }


    upload(url, data) {
        //HEADER CONFIG
        let headers = new HttpHeaders({});
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }
        //console.log("%c [ POST ]  " + url, this.colorUrlGet);
        return this.http.post(url, data, { headers: headers })
        .pipe(
            map(res => {
                if(res != null){
                    this.checkExpirationToken(res['HttpHeaders']);
                    let response = res;
                    this.setToken(response);
                }
                return res;
            }),
            catchError((res: any, caught: Observable<any>) => {
                console.log("%c [ POST ]  " + url, this.colorUrlResponseError);
                this.snackBar.open("❗ Erreur serveur", "", this.mdSnackBarConfigError);
                this.checkExpirationToken(res['status']);
                this._loaderService.removeLoader();
                return throwError(caught);
            })
        );
    }

    patch(url, data) {
        //HEADER CONFIG
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }
        //console.log("%c [ PATCH ]  " + url, this.colorUrlGet);
        return this.http.patch(url, data, ({ headers: headers }))
        .pipe(
            map(res => {
                this.checkExpirationToken(res['status']);
                //console.log("%c [ PATCH ]  " + url, this.colorUrlResponse);
                let response = res;
                this.setToken(response);
                return res;
            }),
            catchError((res: any, caught: Observable<any>) => {
                this._loaderService.removeLoader();
                console.log("%c [ PATCH ]  " + url, this.colorUrlResponseError);
                this.snackBar.open("❗ Erreur serveur", "", this.mdSnackBarConfigError);
                this.checkExpirationToken(res['status']);
                return throwError(caught);
            })
        );
    }

    delete(url) {
        //HEADER CONFIG
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        if (this.token != "") {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }
        //console.log("%c [ DELETE ]  " + url, this.colorUrlGet);
        return this.http.delete(url, { headers: headers }).map(res => {
    
            if (res && res['status'] == 200) {
                console.log("%c [ DELETE ]  " + url, this.colorUrlResponse);
                let response = res;
                this.setToken(response);
                this.checkExpirationToken(res['status']);
            }
            else {
                //console.log("%c [ DELETE ]  " + url, this.colorUrlResponseError);
            }
            
            return res;
        });
    }

    checkExpirationToken(responseStatus) {
        if (responseStatus == 401) {
            this.snackBar.open("Session expirée", "", this.mdSnackBarConfigError);
            this.removeToken();
            this.router.navigate(['/']);
        }
    }

    setToken(responseJson) {
        if (responseJson.token != undefined && responseJson.token != "") {
            this.token = responseJson.token;
        }
    }

    removeToken(){
        this.token = "";
    }
    
}