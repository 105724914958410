import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { HttpClient } from './http-client.service';
import { LoaderService } from './../loader.service';
import { User } from './../../Class/user';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';  // Updated for Angular 6/RxJS 6
import {LocalStorage, SessionStorage} from 'ngx-webstorage';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class AuthService {

  @LocalStorage()
  public user;

  constructor(private _loaderService: LoaderService, private _http: HttpClient, private router : Router) {
    if(this.user === undefined && !this.user.hasOwnProperty("id")){
      this.router.navigate(['/']);
    }
  }

  public setUser(data : User){
    this.user = data;
  }

  public login(data) {
    this._loaderService.addLoader();
    return this._http.post(environment.API_URL + "users/login", data)
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        return res;
      }),
      catchError((error: Response) => {
        this._loaderService.removeLoader();
        return throwError('Serveur erreur');
      })
    );
  }

  public logout() {
    /*this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "users/logout").map(res => {
      this._loaderService.removeLoader();
      return res;
    }).catch((error: Response) => {
      this._loaderService.removeLoader();
      return throwError('Serveur erreur');
    });*/
    this.user = null;
    this._http.removeToken();
    this.router.navigate(['/']);


  }

}
