import { Subscription } from 'rxjs/Subscription';
import { SpeedSearchComponent } from './../../../../speed-search/speed-search.component';
import { Profil } from './../../../../../Class/profil';
import { AddSocieteDialogComponent } from './../../../../add-societe-dialog/add-societe-dialog.component';
import { Societe } from './../../../../../Class/societe';
import { SlEventService } from './../../sl-event-manager.service';
import { Historic } from './../../../../../Class/historic';
import { ActivatedRoute } from '@angular/router';
import { HistoricService } from './../../../../historic/historic.service';
import { SourceListeService } from './../../../../../Services/source-liste.service';
import { SourceListeSociete } from './../../../../../Class/source-liste-societe';
import { SourceListe } from './../../../../../Class/source-liste';
import { Client } from './../../../../../Class/client';
import { ClientService } from './../../../../../Services/client.service';
import { Component, OnInit, EventEmitter, Input, ViewChild, HostListener } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { ConfirmDialogComponent } from "./../../../../confirm-dialog/confirm-dialog.component";
import { Location } from "@angular/common";

@Component({
    selector: 'app-tab-sl',
    templateUrl: './tab-sl.component.html',
    styleUrls: ['./tab-sl.component.less'],
    providers: [SourceListeService]
})
export class TabSlComponent implements OnInit {

    //-----------------------------------
    // Init varialbes
    //-----------------------------------
    public etats: string[] = []; //Listes des etats possibles
    public clients: Client[] = []; //Liste des clients
    public tempsClients: Client[] = []; //Lites des clients sauvegardée
    public clientSelected: string = ""; //Client séléctionné label à afficher
    public client: Client = new Client() //Client séléctionnée
    public currentSourceListe: SourceListe = new SourceListe(); //Source liste en cours
    public arboActive: Societe;
    public arbo: any[] = [];
    public safeArbo: any[] = [];
    public totalProfil: number = 0;
    public tabDefault = 0;
    private subscriptions: Array<Subscription> = [];


    constructor(
        private _clientService: ClientService,
        private _sourceListe: SourceListeService,
        private _hs: HistoricService,
        private activatedRoute: ActivatedRoute,
        private _slEvent: SlEventService,
        private dialog: MatDialog,
        private location: Location,
        private _mdSnack: MatSnackBar,
    ) {  }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 46) {
            this.deleteClient();
        }
    }//END @HostListener


    _timeout: any = null;
    updateReelTime(event){
        if(this._timeout){   //if there is already a timeout in process cancel it
            window.clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout( ()=>{
            this._timeout = null;
            this.saveSourceListe();
        }, 500 );
    }

    filterClient(event) { //AutoCompletion liste des clients
        const val = event.toLowerCase();
        const temp = this.tempsClients.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.clients = temp;
    }//END filterClient()

    changeClientSelected(item: Client) { //Clique dans la liste des clients
        this.client = item;
        this.clientSelected = item.nom;
    }//END changeClientSelected()

    deleteClient() {
        if (this.clientSelected !== null && this.clientSelected !== undefined) {
            this.clientSelected = '';
            this.currentSourceListe.client = new Client();
            this.clients = this.tempsClients;
        }
    }//END deleteClient()

    setArboActive(item: Societe) {
        this.arboActive = item;
        this._slEvent.set_editSociete(this.arboActive.id);
        this._slEvent.set_societeForProfil(this.arboActive);
        let pro = new Profil();
        this._slEvent.set_profil(pro);
    }//END setArboActive()

    //CREATE EVENT FOR FOLDER
    createSocieteInItem() {
        if (this.arboActive) {
            this._slEvent.set_createSociete(this.arboActive);
        }
        else {
            this.createSocieteInSourceListe();
        }
    }//END createSocieteInItem()

    createSocieteInSourceListe() {
        this._slEvent.set_createSociete(null);
    }//END createSocieteInSourceListe()

    createFolder() {
        this._slEvent.set_createFolder(true);
    }//END createFolder()

    saveSourceListe() {
        if (this.currentSourceListe.titre != undefined && this.currentSourceListe.titre.length) {
            this.currentSourceListe.client = new Client();
            this.clientSelected.length == 0 ? delete this.currentSourceListe.client : this.currentSourceListe.client.nom = this.clientSelected;

            if (this.currentSourceListe.client != null) {
                for (let i = 0; i < this.clients.length; i++) {
                    if (this.clients[i].nom == "") {
                        this.clients[i].nom = null;
                    }
                    if (this.clients[i].nom == this.currentSourceListe.client.nom) {
                        this.currentSourceListe.client = this.clients[i];
                        break;
                    }
                }
            }

            if (this.currentSourceListe.id != undefined && this.currentSourceListe.id != null) {
                this._sourceListe.patchSourceListe(this.currentSourceListe).subscribe(res => {
                    setTimeout( ()=>{
                        this._hs.changeTitle(res.titre);
                        //this._mdSnack.open("Source liste modifiée ✔", null, { duration: 2000, extraClasses: ['success'] });
                    },0 )
                });
            }
            else {
                let mdSnackBarConfig = new MatSnackBarConfig();
                mdSnackBarConfig.duration = 2000;
                mdSnackBarConfig.panelClass = 'success'; 

                this._sourceListe.saveSourceListe(this.currentSourceListe).subscribe(res => {
                    this._hs.changeTitle(res.titre);
                    this._hs.changeUrl(`/gestion/source-liste/${res.id}`);
                    this.location.replaceState("/gestion/source-liste/"+res.id);
                    this._mdSnack.open("La source liste a bien été ajoutée ✔", null, mdSnackBarConfig);
                    this.currentSourceListe.id = res.id;
                    this._slEvent.set_sourceListe(this.currentSourceListe);
                    this.loadArbo(res.id);
                    //this.setTab(1);


                });
                this._hs.activeUpdate();
            }
        }
    }//END saveSourceListe()
    setTab(index){
        setTimeout( ()=>{
            this.tabDefault = index;
        },2000);
    }
    generateArbo(data: Societe[]) {
        //this.arbo
        this.arbo = [];
        let doItAgain = true;
        const tabOrigine = data;
        let arbos = [];
        while (doItAgain) {
            doItAgain = false;
            for (let i = 0; i < tabOrigine.length; ++i) {
                let elt = tabOrigine[i];
                if (!elt.parent) {
                    arbos['_' + elt.id] = elt;
                }
                else {
                    if (arbos['_' + elt.parent.id]) {
                        (!arbos['_' + elt.parent.id].hasOwnProperty('children')) ? arbos['_' + elt.parent.id].children = [] : '';
                        let elementExist = false;
                        for (let x = 0; x < arbos['_' + elt.parent.id].children.length; ++x) {
                            if (arbos['_' + elt.parent.id].children[x].id == elt.id) {
                                elementExist = true;
                                break;
                            }
                        }
                        if (!elementExist) arbos['_' + elt.parent.id].children.push(elt);
                        arbos['_' + elt.id] = elt;
                    }
                    else {
                        doItAgain = true;
                    }
                }
            }
        }

        let totalProfil = 0;
        let arboTemp = [];

        for (let i = 0; i < data.length; i++) {
            totalProfil += +data[i].profilCount;
            if (arbos["_" + data[i].id].parent == null) {
                arboTemp.push(arbos["_" + data[i].id]);
            }
        }
        this.checkAndSortChildren(arboTemp);
        this.arbo = arboTemp;
        this.totalProfil = totalProfil;

    }//END generateArbo()

    checkAndSortChildren(objArray) {
      Array.from(objArray).forEach(function(currentObj){
        if(currentObj.hasOwnProperty('children')){
          this.checkAndSortChildren(currentObj['children']);
          currentObj['children'].sort(function(a,b){
            var childrenA = a.nom;
            var childrenB = b.nom;
            return (childrenA < childrenB) ? -1 : (childrenA > childrenB) ? 1 : 0;
          });
        }
      }, this);
    }// END checkAndSortChildren

    loadArbo(id) {
        this._sourceListe.getSourListSocietes(id).subscribe((res: Societe[]) => {
            this.safeArbo = [];
            this.safeArbo = res;
            this.generateArbo(res);
        });
    }//END loadArbo()

    init() {
        let item = this.arboActive;
        if (item && item.id != undefined && this.currentSourceListe.id != undefined) {
            let data = {
                source_liste: this.currentSourceListe,
                societe: item
            };
            this._sourceListe.initSociete(data).subscribe(res => {
                this._slEvent.run_reloadArbo();
            });
        }
    }//END init()

    deleteSociete() {
        let item = this.arboActive;
        if (item && item.id != undefined && this.currentSourceListe.id != undefined) {

            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: "Confirmer la suppression de la societé " + this.arboActive.nom },  backdropClass: 'light-dialog', });
            dialogRef.afterClosed().subscribe( res => {
                if (res) {
                    let data = {
                        source_liste: this.currentSourceListe,
                        societe: item
                    };

                    let mdSnackBarConfig = new MatSnackBarConfig();
                    mdSnackBarConfig.duration = 2000;
                    mdSnackBarConfig.panelClass = 'success';

                    this._sourceListe.deleteSociete(data).subscribe(res => {
                        this._slEvent.run_reloadArbo();
                        this.location.replaceState('/gestion/source-liste/'+this.currentSourceListe.id);
                        this._hs.changeUrl('/gestion/source-liste/'+this.currentSourceListe.id);
                        this._mdSnack.open("Société " + data.societe.nom + " supprimée ✔", null, mdSnackBarConfig);
                    });
                }
            });
        }
    }//END deleteSociete()

    addSocieteExistante() {

        let dialogRef = this.dialog.open(AddSocieteDialogComponent, {
            width: "800px",
            height: "600px",
            data: this.currentSourceListe,
            backdropClass: 'light-dialog',
        });
        dialogRef.afterClosed().subscribe(result => { });

    }//addSocieteExistante()

    openSpeedSearch(){
      let dialogRef = this.dialog.open(SpeedSearchComponent, {
            width: "1100px",
            height: "700px",
            backdropClass: 'light-dialog',
            data: this.currentSourceListe
      });
      dialogRef.afterClosed().subscribe(result => {
            //Recherche de profil dans une sourceListe spécifique !
      });
    }//openSpeedSearch

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }//ngOnDestroy

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => { //On regarde les pramètres dans l'url
            const id = params['id']; //On récupére le parametre id voir fichier de routing
            if (id == "new") { //si ID == 'NEW' donc nouvelle source listes
                let link = new Historic({ url: "/gestion/source-liste/new", title: "Nouvelle source liste" }); //Generation de l'onglet nouvelle source liste
                this._hs.addHistoric(link);
                this.currentSourceListe.client = new Client(); //On set le client de la source liste local
                //Ne pas envoyer l'event de la source liste tant que elle n'est pas créer
                //this._slEvent.set_sourceListe(this.currentSourceListe);
            }
            else {

                if (+id) {
                    this._sourceListe.getSourcesListe(id).subscribe((res: SourceListe) => {
                        let link;
                        if (+params['id'] && !+params['sc_id'] && !+params['pf_id']) {
                            link = new Historic({ url: "/gestion/source-liste/" + id, title: res.titre });
                        }
                        if (+params['id'] && +params['sc_id'] && !+params['pf_id']) {
                            link = new Historic({ url: "/gestion/source-liste/" + id + "/" + params['sc_id'], title: res.titre });
                        }

                        if (+params['id'] && +params['sc_id'] && +params['pf_id']) {
                            link = new Historic({ url: "/gestion/source-liste/" + id + "/" + params['sc_id'] + "/" + params['pf_id'], title: res.titre });
                        }
                        this._hs.addHistoric(link);
                        if (res.client) { this.client = res.client; this.clientSelected = res.client.nom } else { res.client = new Client() }
                        this.currentSourceListe = res;
                        this.tabDefault = 1;
                        //Initialisation de la source depuis l'url
                        if (res.id) {
                            this._slEvent.set_sourceListe(res);
                            this.loadArbo(res.id);
                        }

                        if (+params['sc_id']) {
                            setTimeout(() => {
                                this._slEvent.set_arboActive(+params['sc_id']);
                            }, 1000)

                        }



                    });
                }
            }
        });

        //Get ETats
        this.etats = this._sourceListe.getEtatList();

        //Get Clients
        this._clientService.getClients().subscribe(res => {
            this.clients = res;
            this.tempsClients = [...this.clients]; //For filter
        });

        this.subscriptions.push(
            this._slEvent.reloadArbo.subscribe(res => {
                this.loadArbo(this.currentSourceListe.id);
            }),

            this._slEvent.arboActive.subscribe(res => {
                if (+res) {
                    for (let i = 0; i < this.safeArbo.length; ++i) {
                        if (this.safeArbo[i].id == res) {
                            this.arboActive = this.safeArbo[i];
                            this.setArboActive(this.safeArbo[i]);
                        }
                    }
                }
            }),
            this._slEvent.addSocietePopUp.subscribe((res: Societe) => {

                let slSociete = new SourceListeSociete();
                slSociete.societe = res;
                slSociete.source_liste = this.currentSourceListe;
                slSociete.parent = null;

                // Formerly this._sourceListe.updateSocieteParent(...)
                this._sourceListe.addSourceListeSociete(slSociete).subscribe(res => {
                    this._slEvent.run_reloadArbo();
                });
            })
        )



    }//END ngOnInit()
}
