import { Historic } from './../../Class/historic';
import { HistoricService } from './../../Components/historic/historic.service';
import { Router } from '@angular/router';
import { AuthService } from './../../Services/Tools/auth.service';
import { Component, OnInit } from '@angular/core';
import {LocalStorage, SessionStorage} from 'ngx-webstorage';

@Component({
	selector: 'app-mvm',
	templateUrl: './mvm.component.html',
	styleUrls: ['./mvm.component.less']
})
export class MvmComponent implements OnInit {

	@LocalStorage()
  	private token;

	constructor(private _authService : AuthService, private router : Router, private _historicService : HistoricService, private _auth : AuthService) {
		
	}

	ngOnInit() {
		let link = new Historic({ url: "/gestion/source-liste", title: "Source listes" });
        this._historicService.addHistoric(link);
	}

}
