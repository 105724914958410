import { SlAddEditComponent } from './Components/source-listes/sl-add-edit/sl-add-edit.component';
import { SocieteComponent } from './Components/societe/societe.component';
import { OrganigrammesComponent } from './Components/organigrammes/organigrammes.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { MvmComponent } from './Pages/mvm/mvm.component';
import { SlHomeComponent } from './Components/source-listes/sl-home/sl-home.component';
import { ResearchComponent } from "./Components/research/research.component";



const APP_ROUTES: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'gestion',
        component: MvmComponent,
        children: [
            {
                path: '', redirectTo: '/gestion/source-liste', pathMatch: 'full' //Route par default pour /gestion
            },
            {
                path: 'source-liste', component: SlHomeComponent //Liste des sources listes
            },
            {
                path: 'source-liste/:id', component: SlAddEditComponent //Edition d'une source liste
            },
            {
                path: 'source-liste/:id/:sc_id', component: SlAddEditComponent, pathMatch: 'full' //Edition d'une source liste
            },
            {
                path: 'source-liste/:id/:sc_id/:pf_id', component: SlAddEditComponent, pathMatch: 'full'//Edition d'une source liste
            },
            {
                path: 'organigrammes', component: OrganigrammesComponent //Liste des sociétés
            },
            {
                path: 'societe/:id', component: SocieteComponent //Edition d'une société
            },
            {
                path: 'societe-add', component: SocieteComponent //Création d'une société
            },
            {
                path: 'research', component: ResearchComponent // Recherche de profil
            }
        ]
    },
    {
        path: '**',
        component: LoginComponent
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
