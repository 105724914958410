import { Alerte } from './../../Class/alerte';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from "./../../Class/user";
import { LocalStorageService } from 'ngx-webstorage';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Router } from "@angular/router";
import { Profil } from "./../../Class/profil";
import { EtatAlertService } from '../../Services/etat-alert.service';
import { ProfilService } from '../../Services/profil.service';
import { SocieteService } from '../../Services/societe.service';
import { SourceListeService } from '../../Services/source-liste.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SlEventService } from '../source-listes/sl-add-edit/sl-event-manager.service';


@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.less'],
    providers: [EtatAlertService, ProfilService, SocieteService, SourceListeService]
})
export class AlertsComponent implements OnInit {

    alerts: Alerte[] = [ new Alerte({})];
    user: User = this.storage.retrieve('user');
    alertSelected: Alerte[] = [];
    currentAlert: Alerte = new Alerte();
    date: string = "";
    time: string = "";//00:00

    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;


    constructor(public dialogRef: MatDialogRef<AlertsComponent>, private storage: LocalStorageService, private _etatAlert: EtatAlertService, private dialog: MatDialog, private router: Router, private _slEvent: SlEventService, private _profilService: ProfilService, private _sl: SourceListeService, private _societeService: SocieteService) { }

    updateAlert() {
        let data = this.currentAlert;
        let dateParse = new Date(this.date);
        if (this.time != null && this.time != undefined) {
            let hm = this.time.split(":");
            dateParse.setHours(+hm[0], +hm[1]);
        }
        data.date_echeance = "" + dateParse;

        this._etatAlert.addEditAlert(data).subscribe(res => {
            this.loadAlert();
        });

    }

    deleteAlert() {
        if (this.alertSelected.length) {
            let id = +this.currentAlert.id;
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Confirmer la suppression de l'alarme ?` }, backdropClass: 'light-dialog' });

            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this._etatAlert.deleteAlert(id).subscribe(res => this.loadAlert());
                }
            });
        }
    }

    selectAlert() {
        if (this.alertSelected.length) {
            this.currentAlert = this.alertSelected[0];
            let currentDate = new Date(this.currentAlert.date_echeance);
            //Set time for ngModel
            let mi = "" + currentDate.getMinutes(); if (+mi < 10) mi = "0" + mi;
            let ho = "" + currentDate.getHours(); if (+ho < 10) ho = "0" + ho;
            this.time = "" + ho + ":" + mi;
            //Set date for ngModel
            let m = "" + currentDate.getMonth(); m = "" + (+m + 1);
            if (+m < 10) m = "0" + m;
            let d = "" + currentDate.getDate();
            if (+d < 10) d = "0" + d;
            this.date = "" + currentDate.getFullYear() + "-" + m + "-" + d;
        }
    }

    loadAlert() {
        this._etatAlert.getUserAlert().subscribe(res => this.alerts = res);
    }

    nbAlerts(alerts) {
    }

    moveToProfil(alert: Alerte) {
        let profil: Profil;

        // this._sl.getSourcesListe(alert.source_liste.id).subscribe(res => {
        //     this._slEvent.set_sourceListe(res);
        //     let sl = res;
        //     //Get Societe
        //     this._societeService.getSocieteById(alert.societe.id).subscribe(res => {
        //         this._slEvent.set_editSociete(res.id);
        //         let societe = res;
        //         //Get Profil
        //         this._profilService.getProfilById(alert.profil.id).subscribe(res => {
        //             this._slEvent.
        //             let profil = res;
        //         });
        //     });
        // });

        this.router.navigate([`gestion/source-liste/${alert.source_liste.id}/${alert.societe.id}/${alert.profil.id}`]);
        //this._slEvent.set_profil(profil);
        this.dialog.closeAll();


    }
    ngOnInit() {
        //Récupération de la liste des alertes
        this.loadAlert();
    }



    onSort(event) {
        const rows = [...this.alerts];
        const sort = event.sorts[0];


        let property = event.column.name;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;


        if (property == "titre") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if (a.profil[prop] == null) a.profil[prop] = "";
                if (b.profil[prop] == null) b.profil[prop] = "";
                if (a.profil[prop].toLowerCase() < b.profil[prop].toLowerCase()) return v1;
                if (a.profil[prop].toLowerCase() > b.profil[prop].toLowerCase()) return v2;
                return 0;
            });
        }

        if (property == "source_liste") {
            rows.sort((a, b) => {
                if (a.source_liste.titre < b.source_liste.titre) return v1;
                if (a.source_liste.titre > b.source_liste.titre) return v2;
                return 0;
            });
        }
        if (property == "societe") {
            rows.sort((a, b) => {
                if (a.societe.nom < b.societe.nom) return v1;
                if (a.societe.nom > b.societe.nom) return v2;
                return 0;
            });
        }
        if (property == "date_echeance") {
            rows.sort((a, b) => {
                if (new Date(a.date_echeance) < new Date(b.date_echeance)) return v1;
                if (new Date(a.date_echeance) > new Date(b.date_echeance)) return v2;
                return 0;
            });
        }

        if (property == "profil") {
            rows.sort((a, b) => {
                if (a.profil.nom < b.profil.nom) return v1;
                if (a.profil.nom > b.profil.nom) return v2;
                return 0;
            });
        }
        if (property == "profil.tel_portable") {
            rows.sort((a, b) => {
                if (a.profil.tel_portable == null) a.profil.tel_portable = "";
                if (b.profil.tel_portable == null) b.profil.tel_portable = "";
                if (a.profil.tel_portable < b.profil.tel_portable) return v1;
                if (a.profil.tel_portable > b.profil.tel_portable) return v2;
                return 0;
            });
        }


        this.alerts = rows;
    }

}
