import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-langue-dialog',
  templateUrl: './langue-dialog.component.html',
  styleUrls: ['./langue-dialog.component.less']
})
export class LangueDialogComponent implements OnInit {

  public langues = [
    {"label" : "Anglais","selected" : false},
    {"label" : "Espagnol","selected" : false},
    {"label" : "Allemand","selected" : false},
    {"label" : "Italien","selected" : false},
    {"label" : "Portuguais","selected" : false},
    {"label" : "Chinois","selected" : false},
    {"label" : "Arabe","selected" : false},
    {"label" : "Russe","selected" : false},
    {"label" : "Autres","selected" : false}
  ]

  constructor(public dialogRef: MatDialogRef<LangueDialogComponent>) { }

  setLangues(){
    let data = "";
    this.langues.map(res => {
      if(res.selected){
        if(data == "") { data += res.label ; } else { data += " - "+res.label; }
      }
    });
    this.dialogRef.close(data);
  }

  ngOnInit() {
  }

}




