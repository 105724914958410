import { Component, OnInit } from '@angular/core';
import { HistoricService } from './historic.service';
import { Historic } from '../../Class/historic';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/map';



@Component({
    selector: 'app-historic',
    templateUrl: './historic.component.html',
    styleUrls: ['./historic.component.less']
})
export class HistoricComponent implements OnInit {

    links: Array<Historic> = [];

    constructor(private _historicService: HistoricService, private _activatedRoute: ActivatedRoute) {
        this._historicService.observer.subscribe(links => {
            this.links = links;
        });
    }

    removeHistoricLink(item: Historic) {
        this._historicService.removeHistoric(item);
    }



    ngOnInit() {
        //SET HOME PAGE
        //this._historicService.addHistoric(new Historic({url : "/gestion/sl", title: "Source Listes"}));

    }

}
