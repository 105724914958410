import { Secteur } from './secteur';
export class Societe {

        id ?: number;
		nom : string;
		adresse : string;
		telephone : string;
		commentaire : string;
		secteur ?: Secteur;
		secteur_id ?: number;
		societe_ref_id ?: number;
		children : Societe[] = [];
		parent ?: Societe = null;
		dossier : boolean;
		archive ?: boolean;
		date_modification : Date;
        profilCount : number = 0; 

		constructor(options : {} = null){
            if (options) {
                Object.assign(this, options)
            }
        }

		getChildren():Societe[]{
			return this.children;
		}
		getSecteur():Secteur{
			return this.secteur;
		}

        

}
