export class Historic {

        public url: string;
        public title: string;
        public active?: boolean = false;


        constructor(options : {}){
    		if (options) Object.assign(this, options)
    	}

}
