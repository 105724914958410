export class Secteur {


        id : number;
        nom : string;

        constructor(options : {}){
    		if (options) Object.assign(this, options)
    	}


        

}
