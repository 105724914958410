import { SourceListe } from './source-liste';
import { User } from './user';
import { Profil } from './profil';
import { Societe } from './societe';
export class Alerte {

        public id : number = null;
        public titre : string = null;
        public date_echeance : string = null;
        public dateEcheance ?: Date;
        public utilisateur ?: User;
        public source_liste ?:SourceListe;
        public profil ?: Profil;
        public societe ?: Societe; 


        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
            this.generateDate()
    	}

        public generateDate(){
            if(this.date_echeance) this.dateEcheance = new Date(this.date_echeance);
        }


}
