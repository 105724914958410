import { ProfilResearch } from './../Class/profil-research';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';  // Updated for Angular 6/RxJS 6
import { LoaderService } from './loader.service';
import { HttpClient } from './Tools/http-client.service';

@Injectable()
export class ResearchService {


      constructor(private _http: HttpClient, private _loaderService: LoaderService) { }

      getProfiles(search) {
            this._loaderService.addLoader();

            return this._http.post(environment.API_URL + "profils/recherche", search)
                  .pipe(
                        map(res => {
                              this._loaderService.removeLoader();
                              return res as ProfilResearch[];
                        }),
                        catchError((error: Response) => {
                              this._loaderService.removeLoader();
                              return throwError('Erreur serveur')
                        })
                  );
      }

      getProfilesBySourceListe(search) {
            this._loaderService.addLoader();

            return this._http.post(environment.API_URL + "profils/rechercheBySourceListe", search)
                  .pipe(
                        map(res => {
                              this._loaderService.removeLoader();
                              return res as any[];
                        }),
                        catchError((error: Response) => {
                              this._loaderService.removeLoader();
                              return throwError('Erreur serveur')
                        })
                  );
      }

      getProfilesDownload(search) {
            this._loaderService.addLoader();

            this._http.postDownload(environment.API_URL + "profils/recherche/download", search);
      }
}
