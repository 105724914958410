import { ProfilSociete } from './profil-societe';
export class ProfilResearch extends ProfilSociete {

        public latestProfilEtat : any;
        public sourceListeProfilEtat : any[];

    
        constructor(options : {} = null){
    		super(options);
            if (options) Object.assign(this, options);

            if(this.hasOwnProperty("$$index")){
                    Reflect.deleteProperty(this, '$$index');
            }
    	}

}
