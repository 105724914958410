import { AuthService } from './Services/Tools/auth.service';
import { LoaderService } from './Services/loader.service';
import { Component, OnInit } from '@angular/core';
import {LocalStorage, SessionStorage} from 'ngx-webstorage';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  public loader : number = 0;

  @LocalStorage()
  private token;

  constructor(public _Loader : LoaderService, private _auth : AuthService){
    if(this.token === undefined || this.token == null || this.token == ""){
        this._auth.logout(); 
    }
  }

  ngOnInit(){
    //this.loader = 1;
    this._Loader.observer.subscribe(response => {
      setTimeout(_ => this.loader = response);
    })
  }

  
}
