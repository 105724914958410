import { AuthService } from './../../Services/Tools/auth.service';
import { AlertsComponent } from './../alerts/alerts.component';
import { UsersComponent } from './../users/users.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Alerte } from "./../../Class/alerte";
import {LocalStorage, SessionStorage} from 'ngx-webstorage';
import { EtatAlertService } from '../../Services/etat-alert.service';


@Component({
      selector: 'app-header',
      templateUrl: './header.component.html',
      styleUrls: ['./header.component.less'],
      providers: [EtatAlertService]
})
export class HeaderComponent implements OnInit {



      @LocalStorage()
      private user;

      public dateMoment: any;
      alerts: Alerte[] = [];
      nbAlerts: number = 0;

      alertsDuJour : Alerte[] = [];

      constructor(public dialog: MatDialog, public _auth: AuthService, public _etatAlert: EtatAlertService) { }

      setDate() {
            let date = new Date,
                  annee = date.getFullYear(),
                  moi = date.getMonth(),
                  mois = new Array('Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'),
                  j = date.getDate(),
                  jour = date.getDay(),
                  jours = new Array('Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'),
                  h: any = date.getHours(),
                  m: any = date.getMinutes();
            (h < 10) ? h = "0" + h : "";
            (m < 10) ? m = "0" + m : "";
            return '' + jours[jour] + ' ' + j + ' ' + mois[moi] + ' ' + annee + ' - ' + h + ':' + m;
      }

      loadAlertProcess = true;

      ngOnInit() {
            this.dateMoment = this.setDate();
            let self = this;
            setInterval(() => { this.dateMoment = this.setDate() }, 30000); //Every 30secondes

            setTimeout(() => {
                  this.abracadabra();
            }, 3000); //Premier lancement au bout de 3secondes
            setInterval(() => { this.abracadabra() }, 30000);//Puis toutes les 30 secondes
      }

      openDialogUser() {
            let dialogRef = this.dialog.open(UsersComponent, {
                  width: "500px",
                  height: "490px",
                  backdropClass: 'light-dialog',
            });
            dialogRef.afterClosed().subscribe(result => { });
      }

      logout() {
            this._auth.logout();
      }

      openDialogAlerts() {
            let dialogRef = this.dialog.open(AlertsComponent, {
                  width: "750px",
                  height: "520px",
                  backdropClass: 'light-dialog',
            });
            dialogRef.afterClosed().subscribe(result => { });
      }


      changeBodyColor(color: string) {
            let body = document.getElementsByTagName("body")[0];
            body.setAttribute("class", color);
      }


      abracadabra() { //Moteur automatique pour les alertes
            if (this.loadAlertProcess && this.user) {
                  this._etatAlert.getUserAlert().subscribe(
                        res => {
                              this.alerts = res;
                              this.nbAlerts = 0;
                              let now = new Date();
                              this.alerts.map(item => {
                                if (new Date(item.date_echeance) <= now) this.nbAlerts++;
                              });
                        },
                        err => {
                              this.loadAlertProcess = false;
                        }
                  );
            }


      }

}
