import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-error-message',
  template: `
    <h1 md-dialog-title>Erreur : </h1>
    <div md-dialog-content>{{message}}</div>
    <div md-dialog-actions>
      <button mat-button (click)="dialogRef.close(true)">Fermer</button>
    </div>
  `,
  styles: []
})
export class ErrorMessageComponent implements OnInit {

  message = "";

  constructor(public dialogRef: MatDialogRef<ErrorMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.message = this.data.message;
  }

}

