import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { Societe } from './../../Class/societe';
import { SocieteService } from './../../Services/societe.service';
import { Secteur } from './../../Class/secteur';
import { SecteurService } from './../../Services/secteur.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Historic } from './../../Class/historic';
import { HistoricService } from './../historic/historic.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-organigrammes',
    templateUrl: './organigrammes.component.html',
    styles: [],
    host: { '(window:keydown)': 'hotkeys($event)' },
})
export class OrganigrammesComponent implements OnInit {
    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;



    secteurs: Secteur[] = []; //Liste des secteurs
    secteur: Secteur = null; //Secteurs séléctionné

    secteurResearch : string;
    nameResearch: string;

    societes = [ new Societe({})]; //Tableau des sociétées
    societe = []; //Societé séléctionée
    tempSocietes = []; //Tableau temporaire pour la recherche, basée sur les sociétés
    tempSecteurs = [];

    _timeout = null;

    ngxColonnes = [  //{prop : "property", name : "label"}
        { prop: "nom", name: "Nom" }, { prop: "secteur.nom", name: "Secteur" }, { prop: "telephone", name: "Téléphone" }, { prop: "adresse", name: "Adresse" }, { prop: "commentaire", name: "Commentaires" }
    ];


    constructor(
        private _hs: HistoricService,
        private _secteurService: SecteurService,
        private _societeService: SocieteService,
        private router: Router,
        private mdSnackBar: MatSnackBar,
        public dialog: MatDialog
    ) { }

    hotkeys(event) {
        let key = event.which || event.keyCode;
        if (key == 46) {
            this.deleteSociete(this.societe[0]);
        }
    }


    resetSecteur() {
        this.secteur = null;
        this.secteurResearch = '';
        const temp1 = this.tempSocietes;
        this.societes = temp1;

        if(this.nameResearch != ''){
            this._societeService.getSocietesResearch(this.nameResearch, undefined).subscribe(res => {
                this.tempSocietes = res;
                this.societes = this.tempSocietes;
                localStorage.setItem('societes', JSON.stringify(this.societes));
            });
        }
        else{
            this.societes = [];
            localStorage.setItem('societes', JSON.stringify(this.societes));
        }
        localStorage.setItem('secteur', '');
        const temp2 = this.tempSecteurs;
        this.secteurs = temp2;
        this.table.offset = 0;

    }
    resarchFilter(event) {
        if (this._timeout !== null) window.clearTimeout(this._timeout);

        this._timeout = setTimeout(() => {
            const val = event.target.value.toLowerCase();
            if(val !== "") {
                if (this.secteurs.length != 1) {
                    this._societeService.getSocietesResearch(val, undefined).subscribe(res => {
                        this.tempSocietes = res;
                        this.societes = this.tempSocietes;
                        localStorage.setItem('societes', JSON.stringify(this.societes));
                    });
                }
                else{
                    if(this.secteurs.length == 1){
                        this._societeService.getSocietesResearch(val, this.secteurs[0].id).subscribe(res => {
                            this.tempSocietes = res;
                            this.societes = this.tempSocietes;
                            localStorage.setItem('societes', JSON.stringify(this.societes));
                        });
                    }
                    else{
                        const temp = this.societes.filter(d => {
                            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
                        });
                        this.societes = temp;
                        localStorage.setItem('societes', JSON.stringify(this.societes));
                    }
                }
            }
            else{
                if(this.secteurResearch != ''){
                    if(this.secteurs.length == 1){
                        this._societeService.getSocietesResearch(undefined, this.secteurs[0].id).subscribe(res => {
                            this.tempSocietes = res;
                            this.societes = this.tempSocietes;
                            localStorage.setItem('societes', JSON.stringify(this.societes));
                        });
                    }
                    else{
                        this.societes = [];
                        this.tempSocietes = [];
                        localStorage.setItem('societes', JSON.stringify(this.societes));
                    }
                }
                else{
                    this.societes = [];
                    this.tempSocietes = [];
                    localStorage.setItem('societes', JSON.stringify(this.societes));
                }
            }
            localStorage.setItem('nomSociete', val);
            this.table.offset = 0;
        }, 500);


    }

    filterSecteur(event) {
        const val = event;
        if(this.societes.length > 0){
            const temp = this.societes.filter(function (d) {
                return d.hasOwnProperty("secteur") && d.secteur != null && d.secteur.id == val;
            });
            this.societes = temp;
            localStorage.setItem('societes', JSON.stringify(this.societes));
        }
        else{
            const temp = this._societeService.getSocietesResearch(undefined, val).subscribe(res =>{
                this.tempSocietes = res;
                this.societes = this.tempSocietes;
                localStorage.setItem('societes', JSON.stringify(this.societes));
            });
        }
        this.table.offset = 0;
        localStorage.setItem('secteur', this.secteurResearch);
    }

    filterSecteurAutoComplete(event) {
        const val = event.toLowerCase();
        const temp = this.tempSecteurs.filter(d => {
            return d.nom.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.secteurs = temp;
        this.table.offset = 0;
    }

    deleteSociete(item: any) {
        let mdSnackBarConfig = new MatSnackBarConfig();
        mdSnackBarConfig.duration = 2000;
        mdSnackBarConfig.panelClass = 'success';
        if (item.id != undefined && item.id != null) {
            let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: "Supprimer la societé ?" }, backdropClass: 'light-dialog' });
            dialogRef.afterClosed().subscribe(
                res => {
                    if (res) {
                        this._societeService.deleteSociete(item.id).subscribe(res => {
                            const temp = this.tempSocietes;
                            temp.splice(item.$$index, 1);
                            this.societes = temp;
                            this.table.offset = 0;
                            this._societeService.setSocietesCache(this.societes);
                            this.mdSnackBar.open("La source liste à été supprimée ✔", null, mdSnackBarConfig);
                        });
                    }
                },
                (err: void) => {  });
        }
    }

    dblSelect(item: Societe) {
        if ( item != undefined && item.id != undefined && item.id != null) {
            this.router.navigate(['/gestion/societe', item.id]);
        }
    }

    ngOnInit() {
        //Historique de recherche
        let link = new Historic({ url: "/gestion/organigrammes", title: "Organigrammes" });
        this._hs.addHistoric(link);

        this.societes = [];

        //Récupération des Secteurs depuis l'API
        this._secteurService.getSecteurs().subscribe(res => {
            this.secteurs = res;
            this.tempSecteurs = [...this.secteurs];
            this.secteurResearch = localStorage.getItem('secteur');
            //const secteurId = parseInt(localStorage.getItem('idSecteur'));
            // if(secteurId) {
            //     this.secteurs.forEach( item => {
            //         if(item.id == secteurId) {
            //             this.secteurResearch = item.nom;
            //             this._societeService.getSocietesResearch(this.nameResearch, parseInt(localStorage.getItem('idSecteur'))).subscribe(res => {
            //                 this.tempSocietes = res;
            //                 this.societes = this.tempSocietes;
            //                 this.table.offset = 0;
            //             });
            //         }
            //     });
            // }
        });

        this.nameResearch = localStorage.getItem('nomSociete');
        this.societes = JSON.parse(localStorage.getItem('societes'));

        // if(this.nameResearch){
        //     this._societeService.getSocietesResearch(this.nameResearch, parseInt(localStorage.getItem('idSecteur'))).subscribe(res => {
        //         this.tempSocietes = res;
        //         this.societes = this.tempSocietes;
        //         this.table.offset = 0;
        //     });
        // }
        //Récupération de la liste des sociétés depuis l'API
        // this._societeService.getSocietes().subscribe(res => {
        //     //this.societes = res;
        //     this.societes = [];
        //     this.tempSocietes = [...this.societes];
        //     this.table.offset = 0;
    }

    onSort(event) {
        const rows = [...this.societes];
        const sort = event.sorts[0];


        let property = event.column.prop;
        let direction = event.newValue; //asc, desc , end event.prevValue

        let v1 = -1, v2 = 1;
        if (direction == "desc") v1 = 1, v2 = -1;


        if (property == "nom" || property == "telephone" || property == "adresse" || property == "commentaire") { //Basic, clé de premier niveau
            let prop = property;
            rows.sort((a, b) => {
                if (a[prop] == null) a[prop] = "";
                if (b[prop] == null) b[prop] = "";
                if (a[prop].toLowerCase() < b[prop].toLowerCase()) return v1;
                if (a[prop].toLowerCase() > b[prop].toLowerCase()) return v2;
                return 0;
            });
        }


        if (property == "secteur.nom") {
            rows.sort((a, b) => {
                if(a.secteur == null) a.secteur = new Secteur({id:null, nom : ""});
                if(b.secteur == null) b.secteur = new Secteur({id:null, nom : ""});
                if (a.secteur.nom < b.secteur.nom) return v1;
                if (a.secteur.nom > b.secteur.nom) return v2;
                return 0;
            });
        }

        this.societes = rows;
    }

}
