import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
    selector: 'app-confirm-dialog',
    template: `
        <div class="container-fluid">
            <div class="panel panel-default" style="background: #fff!important;">
                <div class="panel-heading" style="font-size:14px">
                Confirmation
                </div>
                <div class="panel-body small-icons">
                    <p style="font-size:16px" class="text-center">{{message}}</p>
                </div>
                <div class="panel-footer" style="text-align: center;">
                    <button mat-raised-button (click)="dialogRef.close(true)">Valider</button>
                    <button mat-raised-button (click)="dialogRef.close(false)">Annuler</button>
                </div>
            </div>
        </div>
  `,
    styles: []
})
export class ConfirmDialogComponent implements OnInit {

    message: string = "";

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.message = this.data.message;
    }

}



