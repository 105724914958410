import { environment } from './../../environments/environment';
import { User } from './../Class/user';
import { HttpClient } from './Tools/http-client.service';
import { LoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';  // Updated for Angular 6/RxJS 6
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class UsersService {

  users : User[];

  constructor(private _loaderService : LoaderService, private _http : HttpClient) { }

  public getUser(id:number){
    this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "users/"+id+"/show")
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        return res as User;
      }),
      catchError( (error:Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
      })
    );
  }

  public deleteUser(id:number){
    this._loaderService.addLoader();
    return this._http.delete(environment.API_URL + "users/"+id+"/delete")
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        return  res;
      }),
      catchError( (error:Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
      })
    );
  }

  public getUsers() {
    this._loaderService.addLoader();
    return this._http.get(environment.API_URL + "users/list")
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        this.users = res as User[];
        return this.users;
      }),
      catchError( (error:Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
      })
    );
  }

  public addUser(data : User){
    this._loaderService.addLoader();
    return this._http.post(environment.API_URL + "users/new", data)
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        return res as User;
      }),
      catchError( (error:Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
      })
    );
  }

  public updateUser(data : User){
    this._loaderService.addLoader();
    return this._http.patch(environment.API_URL + "users/"+data.id+"/update", data)
    .pipe(
      map(res => {
        this._loaderService.removeLoader();
        return res as User;
      }),
      catchError( (error:Response) => {
          this._loaderService.removeLoader();
          return throwError('Serveur erreur');
      })
    );
  }

}
