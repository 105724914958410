export class Client {


        id ?: number = null;
        nom : string = "";

        constructor(options : {} = null){
    		if (options) Object.assign(this, options)
    	}


        

}
