import { DatatableComponent } from '@swimlane/ngx-datatable';
import { SlEventService } from './../source-listes/sl-add-edit/sl-event-manager.service';
import { Profil } from './../../Class/profil';
import { Societe } from './../../Class/societe';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ProfilService } from './../../Services/profil.service';
import { SocieteService } from './../../Services/societe.service';

@Component({
    selector: 'app-transfert-societe',
    templateUrl: './transfert-societe.component.html',
    styleUrls: ['./transfert-societe.component.less'],
    providers : [ProfilService]
})
export class TransfertSocieteComponent implements OnInit {

    @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

    societes: Societe[]
    tempSocietes : Societe[]
    profilToUpdate: Profil
    societeSelected: any[] = []
    search : string = "";

    ngxColonnes = [  //{prop : "property", name : "label"}
        { prop: "nom", name: "Nom" },
        { prop: "secteur.nom", name: "Secteur" },
        { prop: "telephone", name: "Téléphone" },
        { prop: "adresse", name: "Adresse" },
        { prop: "commentaire", name: "Commentaires" }
    ]

    constructor(
        public dialogRef: MatDialogRef<TransfertSocieteComponent>,
        private _societeService: SocieteService,
        private _slEvent: SlEventService,
        private _profilService : ProfilService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }


    resarchFilter(event) {
        const val = event.toLowerCase();
        if(this.tempSocietes != undefined && this.tempSocietes != null && this.tempSocietes.length){
            const temp = this.tempSocietes.filter(d => {
                if(d['nom'] == undefined){
                    d['nom'] = '';
                }
                return d['nom'].toLowerCase().indexOf(val) !== -1 || !val
            });
            this.societes = temp
        }
        this.table.offset = 0;
    }

    transfer(){
        this.profilToUpdate.societe = this.societeSelected[0];

        delete this.profilToUpdate.date_creation;
        delete this.profilToUpdate.date_modification;
        delete this.profilToUpdate.dateCreation;
        delete this.profilToUpdate.dateModification;

        this._profilService.patchAndSave(this.profilToUpdate, this.societeSelected[0]).subscribe(res =>{
            this.dialogRef.close()
        })
    }

    ngOnInit() {
        this.profilToUpdate = this.data;

        if (this._societeService.societes.length) {
            this.societes = this._societeService.societes
            this.tempSocietes = [...this._societeService.societes]
        }
        else {
            this._societeService.getSocietes().subscribe((res: Societe[]) => {
                this.societes = res
                this.tempSocietes = [...this.societes]
            })
        }

    }

}
